import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { db } from "../../firebase/Firebase";

const Accordition3 = ({ item, id, onChangeLesson, lang }) => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy", item?.id, item?.id);
    const q = query(todosref, orderBy("num", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, [item?.id]);

  return (
    <>
      {module?.map((item, key) => (
        <div
          onClick={() => onChangeLesson(item, key)}
          className="course-lists d-flex flex-wrap justify-content-between"
        >
          <div className="csa-left">
            <h6>
              {key + 1}. {item.name}
            </h6>
            <p>
              <i className="icofont-play-alt-2"></i>
              {lang === "Hindi" ? (
                <>{item?.Htime === "" ? <>--</> : `${item?.Htime} Min`}</>
              ) : (
                <>{item?.Etime === "" ? <>--</> : `${item?.Etime} Min`}</>
              )}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Accordition3;
