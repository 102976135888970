import React , {Fragment} from 'react'
import CourseTemp from '../../Courses/CourseTemp'
import VlogPlayerBody from './VlogPlayerBody'

const VlogsPlayer = () => {
  return (
    <Fragment>
       <CourseTemp>
        <VlogPlayerBody />
       </CourseTemp>
    </Fragment>
  )
}

export default VlogsPlayer