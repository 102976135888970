import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import Respond from "../../component/sidebar/respond";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import Accordition from "./Accordition";
import PageHeaderTwo2 from "../../component/layout/pageheader22";
import CourseSideDetail2 from "../../component/sidebar/course-detail2";

const CourseDetailPremium = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref , orderBy('number' , 'asc'));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <Fragment>
      <HeaderTwo />
      <PageHeaderTwo2 />
      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-single-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3>Course Overview</h3>
                      <p>
                        This course will help you building your efficiency
                        towards healthy and safer preps for your competing
                        client and get them ready for the stage winning
                        performance <br /> If you want a VIP membership access then you
                        will get tremendous benefits i.e. you get access to vlog
                        section. <br /> You will witness the case studies of how we do
                        the ultimate peak week and bring the extra ordinary
                        conditioning for our athletes on stage <br /> You will get
                        access to the podcasts which will discuss many great
                        topics with random talk and discussions and sharing the
                        experiences <br /> You can step into the mentorship program in
                        which will get to learn how to apply all the knowledge
                        in real life scenarios which you have learnt in this
                        course and help your clients get stage ready and
                        transformation <br />You will get straight up access to the
                        forums in which you can ask up anything. We will help
                        you clear out all your doubts. <br /> There will be upgradation
                        in the course now and then based on different topics,
                        and will get all the updates for free. <br /> You will be
                        getting access for 1 year to each and every facilities
                        written above. <br /> This is a completely a self paced course
                        you can watch the lectures at your own time pace. <br />
                        Lectures are available both in English and Hindi
                      </p>
                      <h4>What You'll Learn in This Course:</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>
                          CREATING A PALA FOR HEALTHY AND SMOOTH PREP &
                          OFF-SEASON FOR AN ATHLETE.
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i> CREATING THE
                          MOST EFFECTIVE AND SIMPLE NUTRITION AND TRAINING
                          APPROACH.
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>CREATING THE MOST
                          EFFECTIVE AND SIMPLE NUTRITION AND TRAINING APPROACH.
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>REVIEWING THE
                          BLOOD WORK AND FIXING EACH AND EVERY ABNORMALITY IN
                          THE BLOOD WORK
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Decades of
                          nutrition and training experience simplified at it's
                          best.
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i> Designing THE
                          MOST EFFECTIVE PEAK WEEK and POST SHOW RECOVERY
                        </li>

                        <li>
                          <i className="icofont-tick-mark"></i> Taking out the most from off-season and making the improvements during that time 

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Choosing the PED's and incorporating them in the particular phase 

                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>   Learn The art of CONDITIONING and looking FULLER and HARDER on the stage. 

                        </li>
                        <li style={{textTransform:'uppercase'}}>
                          <i className="icofont-tick-mark"></i> With knowledge, comes the power of progressive understanding towards client's goal and purposely act on it. 

                        </li>
                      </ul>
                  
                    </div>
                  </div>
                </div>

                <div className="course-video">
                  <div className="course-video-title">
                    <h4>Course Content</h4>
                  </div>
                  <div className="course-video-content">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        {module?.map((item, key) => (
                          <>
                            <div className="accordion-header" id="accordion01">
                              <button
                                className="d-flex flex-wrap justify-content-between"
                                data-bs-toggle="collapse"
                                data-bs-target={`#videolist${key}`}
                                aria-expanded="true"
                                aria-controls={`#videolist${key}`}
                              >
                                <span>
                                  {item.Module}. {item.NAME}
                                </span>{" "}
                                <span>{item.chapters} chapters</span>{" "}
                              </button>
                            </div>
                            <Accordition item={item} id={key} />
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <Respond />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CourseDetailPremium;
