import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import Respond from "../../component/sidebar/respond";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";

import PageHeaderTwo2 from "../../component/layout/pageheader22";
import CourseSideDetail2 from "../../component/sidebar/course-detail2";

const Refund = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <Fragment>
      <HeaderTwo />

      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-single-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3 className=" text-center p-5">
                        Refund & Cancellation Policy
                      </h3>
                      <div>
                        <p className="term-p">
                   
Welcome to Contest Prep Academy, operated by Delta Infox. We strive to provide you with a valuable learning experience. Please read our Refund & Cancellation Policy carefully to understand the terms related to refunds and cancellations.

<br />
<br />
1. Enrollment Fees:
<br />
<br />

a. All payments for Contest Prep Academy courses are non-refundable.

<br />
<br />
2. Cancellation Policy:
<br />
<br />

a. Once you have enrolled in a course and made the required payment, cancellations are not permitted.
<br />
<br />
3. Course Modifications or Cancellations by Delta Infox:

<br />
<br />

a. Delta Infox reserves the right to modify, suspend, or cancel any course, including its content, schedule, or instructors, without prior notice.
<br />
<br />
b. In the event of a course cancellation by Delta InfoX, participants will be notified, and alternative arrangements, such as access to another course or a refund, will be offered at our discretion.

<br />
<br />


4. Refund Eligibility:
<br />
<br />
a. Refunds will only be considered in exceptional circumstances, such as technical issues preventing access to the course content.
<br />
<br />
b. Requests for refunds must be submitted in writing to info@deltainfox.com within seven (7) days of enrollment, providing detailed information about the issue.
<br />
<br />
c. Delta Infox will review each request on a case-by-case basis. Refunds are not guaranteed and are subject to approval.

<br />
<br />

5. Payment Disputes:
<br />
<br />
a. Initiating a chargeback or payment dispute without first contacting Delta InfoX will result in immediate suspension of course access. We encourage you to reach out to us to resolve any issues promptly.
<br />
<br />
6. Contact Us:
<br />
<br />
a. For refund requests, cancellation inquiries, or any other concerns, please contact us at info@deltainfox.com. We are here to assist you.
<br />
<br />
7. Changes to Refund & Cancellation Policy:
<br />
<br />
a. Delta Infox reserves the right to update or modify this Refund & Cancellation Policy at any time. The latest version will be available on the Contest Prep Academy website.
<br />
<br />
By enrolling in Contest Prep Academy, you acknowledge and agree to abide by the terms of this Refund & Cancellation Policy.
<br />
<br />
Thank you for choosing Contest Prep Academy.
<br />
<br />
Delta Infox





                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Refund;
