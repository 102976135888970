import React , {Fragment} from 'react'
import './VideoLecture.css'
import CourseTemp from '../Courses/CourseTemp'
import VideoBody from './VideoBody'
import { useLocation } from 'react-router-dom'



const VideoLecture = () => {

const location = useLocation()

let item = location.state.item
let chapter = location.state.chapter


  return (
    <Fragment>
    <CourseTemp>
      <VideoBody item={item} chapter={chapter}/>
    </CourseTemp>
       </Fragment>
  )
}

export default VideoLecture