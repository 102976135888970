import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderTwo from "../../component/layout/header-2";
import PageHeader from "../../component/layout/pageheader";

import Footer from "../../component/layout/footer";
import { doc, getDoc } from "firebase/firestore";
import {toast} from 'react-hot-toast'
import { database, db, logInWithEmailAndPassword } from "../../firebase/Firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const title = "Forget Password";
const btnText = "Forget Password";

const Forget = () => {
    const [email, setEmail] = useState("");

    const navigate = useNavigate();
  
  
    const handleSubmit = async(e)=>{
      e.preventDefault()
      const emalVal = e.target.email.value;
      sendPasswordResetEmail(database,emalVal).then(data=>{
    
          toast.success("Check your gmail");
            setEmail('')
            navigate("/login")
   
      }).catch(err=>{
          toast.error(err.code);
     
      })
  }


  return (
    <Fragment>
      <HeaderTwo />
      <PageHeader title={"Forget Password?"} curPage={"Forget Password"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            {/* <h3 className="title">{title}</h3> */}
            <form  onSubmit={(e)=>handleSubmit(e)} className="account-form">
              <div className="form-group">
              <input
                required
                className="input-log"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                name="email"
                id="email"
              />
              </div>
            
            
              <div className="form-group text-center">
                <button type="submit" className="d-block lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Don’t Have any Account? <Link to="/SignUp">Sign Up</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Forget;

