import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import HeaderTwo from "../../component/layout/header-2";
import PageHeader from "../../component/layout/pageheader";
import Footer from "../../component/layout/footer";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../firebase/Firebase";
import { toast } from "react-hot-toast";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";

const title = "Register Now";
// const socialTitle = "Register With Social Media";
const btnText = "Register Now";

const SignupPage = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const registerWithEmailAndPassword = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
        try {
            const res = await createUserWithEmailAndPassword(auth ,email, password);
            const user = res.user;
            await setDoc(doc(db, "users" , email.toLowerCase()), {
              uid: user.uid,
              name: name,
              email: email,
              role: 'user',
              loginPermission: true,
              // MAC: data,
              createdAt: serverTimestamp(),
              Module1 :0,
              Module2: 0,
              profileimg: ''
      
            }).then(()=> {
              toast.success('Account Created Successfully', {
                duration: 4000,
                position: 'top-right'
              });
            //   navigate('/')
            })
      
            await setDoc(doc(db, "usersLesson" , user.uid), {
              uid: user.uid,
              name: name,
              email: email,
              role: 'user',
              loginPermission: true,
              // MAC: data,
              createdAt: serverTimestamp(),
              Module1: 0,
              Module2: 0,
              profileimg: ''
      
            })
          } catch (err) {
            console.error(err);
            toast.error(err.message , {
              duration: 2000,
              position: 'top-right'
            });
          }
    } else {
      toast.error("Password does not match");
    }
  };

  return (
    <Fragment>
      <HeaderTwo />
      <PageHeader title={"Register Now"} curPage={"Sign Up"} />
      <div className="login-section padding-tb section-bg">
        <div className="container">
          <div className="account-wrapper">
            <h3 className="title">{title}</h3>
            <form
              onSubmit={registerWithEmailAndPassword}
              className="account-form"
            >
              <div className="form-group">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  name="name"
                  required
                  placeholder="User Name"
                />
              </div>
              <div className="form-group">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  name="password"
                  required
                  placeholder="Password"
                />
              </div>
              <div className="form-group">
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  name="password"
                  required
                  placeholder="Confirm Password"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
            <div className="account-bottom">
              <span className="d-block cate pt-10">
                Are you a member? <Link to="/Login">Login</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default SignupPage;
