import React, { useContext, useEffect, useRef, useState } from "react";
import "./Reply.css";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../../firebase/Firebase";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import { toast } from "react-hot-toast";
import { v4 } from "uuid";
import {
  AiFillPauseCircle,
  AiOutlineClose,
  AiOutlineSend,
} from "react-icons/ai";
import { MdCancel, MdDelete, MdKeyboardVoice } from "react-icons/md";
import { RiSendPlane2Fill } from "react-icons/ri";
import "./Reply2.css";
import Lightbox from "yet-another-react-lightbox";
import Modal from "react-modal";
import { FileUploader } from "react-drag-drop-files";
import Loader from "../../../component/sidebar/Loader";
import Replycomponent from "../Replycomponent";
import vmsg from "vmsg";
import { SlideshowLightbox } from "lightbox.js-react";
// import 'lightbox.js-react/dist/index.css'
import { v4 as uuidv4 } from "uuid";

const ReplyForumBody = () => {
  const [load, setLoad] = useState(true);
  const [uuid, setuuid] = useState(uuidv4());
  const [uuid2, setuuid2] = useState(uuidv4());

  const { id } = useParams();
  const [forum, setForum] = useState();

  const { user } = useContext(AuthContext);
  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Forum", id), (docSnap) => {
      setForum(docSnap.data());
      setLoad(false);

      if (!docSnap.data().path) {
        console.log("Not");

        // Set the "capital" field of the city 'DC'
        updateDoc(doc(db, "Forum", id), {
          path: `/ReplyData/${id}`,
        });
      } else {
        console.log("Exist");
      }
    });

    return () => {
      unsub();
    };
  }, [id]);

  const fileTypes = ["JPEG", "PNG"];
  // eslint-disable-next-line no-unused-vars
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //   subtitle.style.color = "#f00";
  }

  function closeModal() {
    document.body.style.overflow = "unset";
    setIsOpen(false);
  }

  const [name, setName] = useState("");
  const [tags, setTags] = useState("");
  const [forumdata, setForumData] = useState({});

  const addReply = async () => {
    if (file) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `forums/${new Date().getTime()} - ${file.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await addDoc(collection(db, "Forum", id, "reply"), {
            Image: url,
            reply: name,
            tags,
            user: getUser?.name,
            email: getUser?.email,
            createdAt: moment().format(),
            profileimg: getUser?.profileimg || "",
            uid: user?.uid,
          });
          addDoc(collection(db, "usersLesson", forum?.userId, "Notification"), {
            Notification: `Someone Replied on Your Forum`,
            markasRead: false,
            path: forum?.path,
            createdAt: serverTimestamp(),
            type: "type1",
          })
            .then(() => {
              setuuid("");
              toast("Reply Send", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              closeModal();
            })
            .then(() => {
              updateDoc(doc(db, "Forum", id), {
                lastreply: moment().format(),
                replies: increment(1),
                createdAt: moment().format(),
              });
              setName("");
              setFile();
              setTags("");
            });
        } catch (err) {
          console.log(err);
        }
      };
      uploadImg();
    } else {
      await addDoc(collection(db, "Forum", id, "reply"), {
        reply: name,
        tags,
        user: getUser?.name,
        email: getUser?.email,
        createdAt: moment().format(),
        profileimg: getUser?.profileimg || "",
        uid: user?.uid,
      });
      addDoc(collection(db, "usersLesson", forum?.userId, "Notification"), {
        Notification: `Someone Replied on Your Forum`,
        markasRead: false,
        path: forum?.path,
        createdAt: serverTimestamp(),
        type: "type1",
      })
        .then(() => {
          setuuid("");
          toast("Reply Send", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          updateDoc(doc(db, "Forum", id), {
            lastreply: moment().format(),
            replies: increment(1),
            createdAt: moment().format(),
          });
          setName("");
          setFile();
          setTags("");
        });
    }
  };

  useEffect(() => {
    setLoad(true);
    const q = query(
      collection(db, "Forum", id, "reply"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setForumData(cities);
      setLoad(false);
    });

    return () => {
      unsubscribe();
    };
  }, [id]);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordings, setRecordings] = useState([]);
  const recorderRef = useRef(null);
  const audioContextRef = useRef(null);

  useEffect(() => {
    recorderRef.current = new vmsg.Recorder({
      wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
    });

    // Perform audio initialization when the component is mounted
  }, []);

  const startRecording = async () => {
    setIsLoading(true);

    audioContextRef.current = new AudioContext();
    // await recorderRef.current.initAudio(audioContextRef.current);
    await recorderRef.current.initAudio();
    await recorderRef.current.initWorker();
    recorderRef.current.startRecording();
    setIsLoading(false);
    setIsRecording(true);
  };

  const stopRecording = async () => {
    setIsLoading(true);
    const blob = await recorderRef.current.stopRecording();
    setIsLoading(false);
    setIsRecording(false);
    setRecordings([URL.createObjectURL(blob)]);
    audioContextRef.current.close(); // Close the AudioContext
  };

  const deleteRecording = () => {
    setRecordings([]);
  };

  const addReplyAudio = async () => {
    if (recordings.length === 0) {
      console.log("No audio recorded.");
      return;
    }

    const audioBlob = await fetch(recordings[0]).then((res) => res.blob());

    const audioRef = ref(storage, `forum-audios/${user.uid}/${v4()}.mp3`);

    uploadBytes(audioRef, audioBlob).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        addDoc(collection(db, "Forum", id, "reply"), {
          tags,
          reply: name || "",
          user: getUser?.name,
          email: getUser?.email,
          createdAt: moment().format(),
          profileimg: getUser?.profileimg || "",
          audioURL: url,
          uid: user?.uid,
        });
      });
    });
    addDoc(collection(db, "usersLesson", forum?.userId, "Notification"), {
      Notification: `Someone Replied on Your Forum`,
      markasRead: false,
      path: forum?.path,
      createdAt: serverTimestamp(),
      type: "type1",
    })
      .then(() => {
        toast("Reply Send", {
          duration: 2000,
          position: "top-center",
          icon: "✔",
          style: {
            borderRadius: "10px",
            background: "#3499EE",
            color: "#fff",
          },
        });
        closeModal();
      })
      .then(() => {
        updateDoc(doc(db, "Forum", id), {
          lastreply: moment().format(),
          replies: increment(1),
          createdAt: moment().format(),
        });

        setFile();
        setTags("");
      });
    setRecordings([]);
  };

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [replydata, setReplydata] = useState();

  function openModal2(item) {
    setIsOpen2(true);
    setReplydata(item);
  }

  function afterOpenModal2() {
    // references are now sync'd and can be accessed.
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  const [replytype, setReplytype] = useState("");

  const addReply1 = async () => {
    await addDoc(collection(db, "Forum", id, "reply", replydata?.id, "reply"), {
      reply: replytype,
      tags,
      user: getUser?.name,
      email: getUser?.email,
      createdAt: moment().format(),
      profileimg: getUser?.profileimg || "",
      path: forum?.path,
    })
      .then(() => {
        toast("Reply Send", {
          duration: 2000,
          position: "top-center",
          icon: "✔",
          style: {
            borderRadius: "10px",
            background: "#3499EE",
            color: "#fff",
          },
        });
        closeModal2();
      })
      .then(() => {
        updateDoc(doc(db, "Forum", id), {
          lastreply: moment().format(),
          replies: increment(1),
        });
        setReplytype("");
      });
    // setDoc(doc(db, "usersLesson", forum.userId, "Notification", uuid), {
    //   Notification: `Someone Replied on Your Forum`,
    //   markasRead: false,
    //   uuid: uuid,
    //   path: forum?.path,
    //   createdAt: serverTimestamp(),
    // })
    if (user?.uid !== replydata?.uid) {
      addDoc(collection(db, "usersLesson", replydata?.uid, "Notification"), {
        Notification: `Someone Replied on Your Forum`,
        markasRead: false,
        path: forum?.path,
        createdAt: serverTimestamp(),
        type: "type1",
      });
    }

    if (user?.uid !== forum?.userId) {
      addDoc(collection(db, "usersLesson", forum?.userId, "Notification"), {
        Notification: `Someone Replied on Your Forum`,
        markasRead: false,
        path: forum?.path,
        createdAt: serverTimestamp(),
        type: "type1",
      });
    }
  };

  if (load === true) {
    return <Loader />;
  } else {
    return (
      <div className="ReplyForumBody">
        <div className="ReplyForumHead">
          <div className="main-y  grid-c1 ">
            <div className="data-reply  grid-c2 ">
              <div className="reply-data-s  ">
                <div className="reply-main-data   ">
                  <div className="border-reply ">
                    <div>
                      <div className="head-reply-comment">
                        <div>
                          <button
                            onClick={() => navigate(-1)}
                            className="btn-head-comm"
                          >
                            Back
                          </button>
                          <h1 className="reply-h1">{forum?.name}</h1>
                          <div className="span-2-reply">
                            <span>Posted by</span>
                            <span className="name-hov"> {forum?.user}</span>
                            <span> on</span>
                            <span> </span>
                          </div>
                          <div className="para-1-reply">
                            <p className="wrp">{forum?.description}</p>
                          </div>
                          {forum?.lastreply === "0" ? (
                            <></>
                          ) : (
                            <div className="span-2-reply">
                              <span className="name-hov">Last Reply</span>
                              <span> {moment(forum?.lastreply).fromNow()}</span>
                            </div>
                          )}

                          {forum?.Image && (
                            <SlideshowLightbox className="slide-box-section">
                              <img
                                className="forumimgimg"
                                src={forum?.Image}
                                alt=""
                              />
                            </SlideshowLightbox>
                          )}

                          {/* {forum?.Image ? (
                            <div className="forumimg">
                              <img
                                onClick={() => setOpen(true)}
                                className="forumimgimg"
                                src={forum?.Image}
                                alt=""
                              />
                            </div>
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="reply-count reply-btn-count">
                      <h1>{forum?.replies} Replies</h1>
                      {/*  */}
                      <div className="btn-side">
                        {forum?.verified === "approved" ? (
                          <>
                            <div className="audio-section">
                              {isRecording ? (
                                <button
                                  className="btn-rec-fun"
                                  onClick={stopRecording}
                                >
                                  <div className="audio-r1">
                                    <AiFillPauseCircle size={20} />
                                    <h1 className="aud-rr-1">
                                      Audio Recording
                                    </h1>
                                  </div>
                                </button>
                              ) : (
                                <>
                                  {recordings.length > 0 ? (
                                    ""
                                  ) : (
                                    <MdKeyboardVoice
                                      onClick={startRecording}
                                      size={25}
                                    />
                                  )}
                                </>
                              )}

                              {recordings.length > 0 && (
                                <div className="audio-record">
                                  <div className="audio-record2">
                                    <audio
                                      className="audio-s"
                                      autoPlay
                                      controls
                                    >
                                      <source
                                        src={recordings[0]}
                                        type="audio/mp3"
                                      />
                                      {/* Your browser does not support the audio element. */}
                                    </audio>
                                    <div className="audio-section-icons">
                                      <MdDelete
                                        onClick={deleteRecording}
                                        size={20}
                                      />

                                      <RiSendPlane2Fill
                                        onClick={addReplyAudio}
                                        size={20}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <button onClick={openModal} className="btn-reply-s">
                              Reply
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <hr />
                    {forumdata.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        {Array.isArray(forumdata)
                          ? forumdata.map((item, key) => {
                              return (
                                <>
                                  <div className="another-reply-1">
                                    <div className="d-f-reply">
                                      <div className="user-dp-time-member">
                                        <div>
                                          {item?.profileimg === "" ? (
                                            <img
                                              className="user-reply-dp"
                                              src={require("../../../assets/images/clients/avater.jpg")}
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              className="user-reply-dp"
                                              src={item?.profileimg}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                        <div className="user-data-t">
                                          <h1>{item?.user}</h1>
                                          <p>
                                            {moment(item?.createdAt).format(
                                              "MMMM Do YYYY , h:mm:ss a"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <button
                                        onClick={() => openModal2(item)}
                                        className="btn-reply-s11"
                                      >
                                        Reply
                                      </button>
                                    </div>

                                    {item?.audioURL ? (
                                      <>
                                        <div className="section-reply-p2">
                                          <audio
                                            controlsList="nodownload noplaybackrate"
                                            controls
                                          >
                                            <source
                                              src={item?.audioURL}
                                              type="audio/mp3"
                                            />
                                          </audio>
                                        </div>
                                        <Replycomponent id={id} item={item} />
                                      </>
                                    ) : (
                                      <>
                                        <div className="section-reply-p2">
                                          <p className="reply-para-uu wrp">
                                            {item?.reply}
                                          </p>
                                        </div>
                                        <Replycomponent id={id} item={item} />
                                      </>
                                    )}
                                  </div>
                                  <hr />
                                </>
                              );
                            })
                          : null}
                      </>
                    )}
                  </div>
                  <div className="btn-side"></div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >
            <div className="section1-reply">
              <div className="reply-headings">
                <h1 className="reply-h1-y">Reply to: {forum?.user}</h1>
              </div>

              <div className="svg-close-modal">
                <AiOutlineClose onClick={closeModal} />
              </div>
            </div>
            <hr />
            <div>
              <div className="section-2-reply">
                <div className="text-area-modal-s">
                  <textarea
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="section-2-replytext"
                    placeholder="Type your reply here"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
                <div className="upload-options2">
                  <FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                  />
                </div>
              </div>
              <hr />
              <div className="section-2-reply-1">
                <textarea
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                  placeholder="Type one or more tag, comma separated "
                  className="text-area-modal-2"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <hr />
              <div className="check-box-post-btn-s">
                <div>
                  <button
                    disabled={!name}
                    onClick={() => {
                      addReply();
                    }}
                    className="post-btn-dis"
                  >
                    Send <AiOutlineSend />
                  </button>
                  <button
                    onClick={() => {
                      closeModal();
                    }}
                    className="post-btn-dis"
                  >
                    Cancel <MdCancel />
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={modalIsOpen2}
            onAfterOpen={afterOpenModal2}
            onRequestClose={closeModal2}
            contentLabel="Example Modal"
          >
            <div className="section1-reply">
              <div className="reply-headings">
                <h1 className="reply-h1-y">Reply to:{replydata?.reply}</h1>
              </div>

              <div className="svg-close-modal">
                <AiOutlineClose onClick={closeModal2} />
              </div>
            </div>
            <hr />
            <div>
              <div className="section-2-reply">
                <div className="text-area-modal-s">
                  <textarea
                    value={replytype}
                    onChange={(e) => setReplytype(e.target.value)}
                    className="section-2-replytext"
                    placeholder="Type your reply here1"
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>

              <hr />
              <div className="check-box-post-btn-s">
                <div>
                  <button
                    disabled={!replytype}
                    onClick={() => {
                      addReply1();
                    }}
                    className="post-btn-dis"
                  >
                    Send <AiOutlineSend />
                  </button>
                  <button
                    onClick={() => {
                      closeModal2();
                    }}
                    className="post-btn-dis"
                  >
                    Cancel <MdCancel />
                  </button>
                </div>
              </div>
            </div>
          </Modal>

          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={[{ src: forum?.Image }]}
          />
        </div>
      </div>
    );
  }
};

export default ReplyForumBody;
