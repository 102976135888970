import { SlideshowLightbox } from 'lightbox.js-react'
import React from 'react'

const Slidebox = ({getdata}) => {
  return (
    <div>

{getdata && (
  <SlideshowLightbox className="slide-box-section">
    {getdata.map((item) => (
      <img className="case-img-s" src={item?.img} alt="" key={item.id} />
    ))}
  </SlideshowLightbox>
)}



    </div>
  )
}

export default Slidebox