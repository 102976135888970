/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Fragment } from "react";

import { Link, useNavigate } from "react-router-dom";
import HeaderTwoAuth from "../../component/layout/header-2-auth";
import PageHeader from "../../component/layout/pageheader";
import Compare from "../../component/section/Compare";
import { useState } from "react";
import { auth, db } from "../../firebase/Firebase";
import { useEffect } from "react";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import "./styles.scss";
import CourseBodyMain from "./CourseBodyMain";
import CourseTemp from "./CourseTemp";
import Loader from "../../component/sidebar/Loader";
import toast from "react-hot-toast";
import Compare3 from "../../component/section/Compare3";

const courseList = [
  {
    imgUrl: require("../../assets/images/course/01.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹30k",
    cate: "Regular Membership",
    reviewCount: "",
    title: "Contest Prep Academy",
    totalLeson: "39x Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/StandardMembership",
  },
  {
    imgUrl: require("../../assets/images/course/02.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹50k",
    cate: "VIP Membership",
    reviewCount: "",
    title: "Contest Prep Academy",
    totalLeson: "39x Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/PremiumMembership",
  },
];

const Subscriptionlist = [
  {
    imgUrl: require("../../assets/images/course/01.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹999",
    cate: "Monthly Subscription",
    reviewCount: "",
    title: "Contest Prep Subscription",
    totalLeson: "39x Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/MonthlySubscription",
  },
];



const Courses = () => {
  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;

  //     script.onload = () => {
  //       resolve(true);
  //     };

  //     script.onerror = () => {
  //       resolve(false);
  //     };

  //     document.body.appendChild(script);
  //   });
  // };

  const [user, setUser] = useState();
  const uid = auth.currentUser.uid;

  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
    const unsub = onSnapshot(
      doc(db, "usersLesson", uid, "MemberShip", "ContestPrepCertification"),
      (doc) => {
        setUser(doc.data());
        setLoad(false);
      }
    );

    return () => unsub();
  }, [uid]);

  // async function showRazorpay() {
  //   try {
  //     const res = loadScript("https://checkout.razorpay.com/v1/checkout.js");
  //     if (!res) {
  //       alert("Razorpay SDK failed to load. Are you online?");
  //       return;
  //     }

  //     const data = await fetch(
  //       "https://us-central1-nodejs-f34dd.cloudfunctions.net/app/subscriptions",
  //       {
  //         method: "POST",
  //       }
  //     ).then((t) => t.json());

  //     // console.log(data);

  //     const options = {
  //       key: "rzp_live_wCNesKyYmEdu3V",
  //       subscription_id: data.id,
  //       plan_id: data.plan_id,
  //       // order_id: data.id,
  //       name: "Contest Prep Subscription",
  //       description: "Contest Prep Monthly Subscription",
  //       // image: "http://localhost:6001/logo.svg",
  //       handler: async function (response) {
  //         // alert(response.razorpay_payment_id);
  //         // console.log(response);
  //         const Subscription = await fetch(
  //           `https://us-central1-nodejs-f34dd.cloudfunctions.net/app/subscriptions/${response.razorpay_subscription_id}`,
  //           {
  //             method: "POST",
  //           }
  //         ).then((t) => t.json());

  //         // alert(response.razorpay_payment_id);
  //         // alert(response.razorpay_order_id);
  //         // alert(response.razorpay_signature);
  //         setDoc(
  //           doc(
  //             db,
  //             "usersLesson",
  //             uid,
  //             "MemberShip",
  //             "ContestPrepSubscription"
  //           ),
  //           {
  //             // payment_id: response.razorpay_payment_id,
  //             // subscription_id: response.razorpay_subscription_id,
  //             // signature: response.razorpay_signature,
  //             loginPermission: true,
  //             ...response,
  //             ...Subscription,
  //           }
  //         );

  //         alert("Transaction successful");
  //       },
  //     };
  //     const paymentObject = new window.Razorpay(options);
  //     paymentObject.open();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const [sub, setSub] = useState();

  useEffect(() => {
    getDoc(
      doc(db, "usersLesson", uid, "MemberShip", "ContestPrepSubscription")
    ).then((docSnap) => {
      if (docSnap.exists()) {
        //    console.log(docSnap?.data().razorpay_subscription_id)

        setSub({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    });
  }, [uid]);

  const navigate = useNavigate();

  useEffect(() => {
    if (sub) {
      toast.error("Home Page is Locked");
      navigate("/Vlogs");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub]);

  // const addSubscription = async() => {
  //   await setDoc(doc(db, "usersLesson", uid , 'ContestPrepSubscription'), {
  //     payment_id: response.razorpay_payment_id,
  //     order_id: response.razorpay_order_id,
  //     signature: response.razorpay_signature,
  //     subscription_id: data.id,
  //   });
  // }

  if (load === true) {
    return <Loader />;
  } else {
    if (user?.Active || sub?.status === "active") {
      return (
        <Fragment>
          <CourseTemp>
            <CourseBodyMain />
          </CourseTemp>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <HeaderTwoAuth />
          <PageHeader title={"Courses"} curPage={"Courses"} />
          <div
            style={{ paddingTop: "110px", paddingBottom: "110px" }}
            className="course-section padding-tb section-bg"
          >
            <div className="container">
              <div className="section-wrapper">
                <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
                  {courseList.map((val, i) => (
                    <div className="col" key={i}>
                      <div className="course-item">

                      <Link to={val.link}>
                      <div className="course-inner">
                          <div className="course-thumb">
                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          </div>
                          <div className="course-content">
                            <div className="course-price">{val.price}</div>
                            <div className="course-category">
                              <div className="course-cate">
                                <a href="#">{val.cate}</a>
                              </div>
                              <div className="course-reiew">
                                <span className="ratting-count">
                                  {" "}
                                  {val.reviewCount}
                                </span>
                              </div>
                            </div>
                            <Link to={val.link}>
                              <h4>{val.title}</h4>
                            </Link>
                            <div className="course-details">
                              <div className="couse-count">
                                <i className="icofont-video-alt"></i>{" "}
                                {val.totalLeson}
                              </div>
                              <div className="couse-topic">
                                <i className="icofont-signal"></i> {val.schdule}
                              </div>
                            </div>
                            <div className="course-footer">
                              <div className="course-author">
                                <img
                                  src={`${val.authorImgUrl}`}
                                  alt={`${val.authorImgAlt}`}
                                />
                                <Link to="/team-single" className="ca-name">
                                  {val.authorName}
                                </Link>
                              </div>
                              <div className="course-btn">
                                <Link to={val.link} className="lab-btn-text">
                                  {val.btnText}
                                  <i className="icofont-external-link"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                            </Link>
                      
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1 mt-5">
                  {Subscriptionlist.map((val, i) => (
                    <div className="col" key={i}>
                        <Link to={val.link}>
                        <div className="course-item">
                        <div className="course-inner">
                          <div className="course-thumb">
                          </div>
                          <div className="course-content">
                            <div className="course-price">{val.price}</div>
                            <div className="course-category">
                              <div className="course-cate">
                                <a href="#">{val.cate}</a>
                              </div>
                              <div className="course-reiew">
                                <span className="ratting-count">
                                  {" "}
                                  {val.reviewCount}
                                </span>
                              </div>
                            </div>
                            <Link to={val.link}>
                              <h4>{val.title}</h4>
                            </Link>
                            <div className="course-details">
                              <div className="couse-count"></div>
                              <div className="couse-topic"></div>
                            </div>
                            <div className="course-footer">
                              <div className="course-author">
                                <img
                                  src={`${val.authorImgUrl}`}
                                  alt={`${val.authorImgAlt}`}
                                />
                                <Link to="/team-single" className="ca-name">
                                  {val.authorName}
                                </Link>
                              </div>
                              <div className="course-btn">
                                <Link to={val.link} className="lab-btn-text">
                                  {val.btnText}
                                  <i className="icofont-external-link"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                            </Link>
                  
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Compare/>
        </Fragment>
      );
    }
  }
};

export default Courses;
