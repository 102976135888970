import React, {Fragment} from 'react'
import CourseTemp from '../../Courses/CourseTemp'
import ReplyForumBody from './ReplyForumBody'
import './Reply.css'

const ReplyForum = () => {
  return (
   <Fragment>
    <CourseTemp>
        <ReplyForumBody />
    </CourseTemp>
   </Fragment>
  )
}

export default ReplyForum