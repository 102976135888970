import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import moment from 'moment'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { db } from '../../firebase/Firebase';


const Replycomponent = ({id,item}) => {



    const [replytreply, setReplyreply] = useState();

    useEffect(() => {
      const q = query(
        collection(db, "Forum", id, "reply", item?.id, "reply"),
        orderBy("createdAt", "asc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setReplyreply(cities);
      });
  
      return () => {
        unsubscribe();
      };
    }, [id]);



  return (
    <div  className='forum-to-reply'>
  {replytreply?.map((item) => (
                                                  <div>
                                                    <div className="forum-id-reply">
                                                      <div className="user-dp-time-member">
                                                        <div>
                                                          {item?.profileimg ===
                                                          "" ? (
                                                            <img
                                                              className="user-reply-dp1"
                                                              src={require("../../assets/images/clients/avater.jpg")}

                                                              alt=""
                                                            />
                                                          ) : (
                                                            <img
                                                              className="user-reply-dp1"
                                                              src={
                                                                item?.profileimg
                                                              }
                                                              alt=""
                                                            />
                                                          )}
                                                        </div>
                                                          <div className="user-reply-to1">
                                                        <div className="user-data-t1">
                                                            <h1>
                                                              {item?.user}
                                                            </h1>
                                                            <p>
                                                              {moment(
                                                                item?.createdAt
                                                              ).format(
                                                                "MMMM Do YYYY , h:mm:ss a"
                                                              )}
                                                            </p>
                                                          </div>
                                                        <div className="">
                                                          <div>
                                                            <p className="reply-to-reply-p">

                                                            {item?.reply}
                                                            </p>
                                                      
                                                          </div>
                                                        </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}


    </div>
  )
}

export default Replycomponent