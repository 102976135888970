import React , {Fragment} from 'react'
import CourseTemp from '../Courses/CourseTemp'
import './Chapter.css'
import ChapterBody from './ChapterBody'
import { useLocation } from 'react-router-dom'

const Chapters = () => {

    const location = useLocation();

    let item = location.state.item




  return (
    <Fragment>
    <CourseTemp>
       <ChapterBody item={item} />
    </CourseTemp>
       </Fragment>
  )
}

export default Chapters