/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "../Module.css";
import { AiFillLock } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../../firebase/Firebase";

const ModuleLessonCard = ({ item, unlock }) => {
  const navigate = useNavigate();

  // const modulesname = ['Module1','Module2']
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy", item.id, item.id);
    const q = query(todosref, orderBy("num", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, [item.id]);

  return (
    <>
      <div className="ModuleLessonCard">
        <div className="ModuleLessonCardLeft">
          <h1> {item?.Module.split(" ")[1]}</h1>
        </div>
        <div className="ModuleLessonCardRight">
          <h1>{item?.NAME}</h1>
          <h5>
            {item?.chapters === 0 ? (
              "Coming Soon..."
            ) : (
              <>{item?.chapters} Chapters</>
            )}
          </h5>
        </div>
        <div className="ModuleLessonCardRight2">
          {unlock === true ? <></> : <AiFillLock size={30} color="black" />}
        </div>
      </div>

      <div className="mod-dd1">
        <div className="mod-dd2">
          <div className="mod-dd4">
            {module?.map((data, i) => (
              <>
                <div
                  onClick={() => {
                    unlock === true
                      ? navigate("/Lectures", {
                          state: { chapter: data, item: item },
                        })
                      : toast.error("Modules Locked");
                  }}
                  key={i}
                  className="mod-dd6"
                >
                  <p style={{ textTransform: "uppercase" }}>
                    {data?.chapter}. {data?.name}
                  </p>
                  <div className="mod-dd7">
                    {/* <p>Hindi /English</p> */}
                    <span>
                      {data?.Hurl === "" ? "" : "Hindi"}{" "}
                      {data?.Hurl === "" ? (
                        <></>
                      ) : (
                        <>{data?.Eurl === "" ? "" : "/"}</>
                      )}{" "}
                      {data?.Eurl === "" ? "" : "English"}
                    </span>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleLessonCard;
