/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react'
import CourseTemp from '../Courses/CourseTemp'
import CaseStudycards from './CaseStudycards'
import './CaseStudy.css'
import { useNavigate } from 'react-router-dom'
import { collection, doc, getDoc, onSnapshot, query } from 'firebase/firestore'
import { auth, db } from '../../firebase/Firebase'
import toast from 'react-hot-toast'

const CaseStudy = () => {


  const navigate = useNavigate();

  const [casestudy, setCaseStudy] = useState();

  useEffect(() => {
    const q = query(collection(db, "CaseStudy"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setCaseStudy(cities);
    });

    return () => {
      unsubscribe();
    };
  }, []);


  const user = auth.currentUser;
  const [memebrship, setMembership] = useState();

  useEffect(() => {
    getDoc(
      doc(
        db,
        "usersLesson",
        user?.uid,
        "MemberShip",
        "ContestPrepCertification"
      )
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setMembership(docSnap.data());
      }
    });
  }, [user?.uid]);






  useEffect(() => {
    if (memebrship?.Subscription === "VIP") {
      if (memebrship?.unlock === false) {
        toast.error("CaseStudy is Locked");
        navigate("/Courses");
      }
    } else if (memebrship?.Subscription === "Regular") {
      toast.error("CaseStudy is Locked");
      navigate("/Courses");
    }
  }, [memebrship]);




  return (
    <Fragment>
    <CourseTemp>

    <h1 className='heading-case'>
    Athlete Case Studies
    </h1>

<div className='case-study-section'>

      {
        casestudy?.map((item)=>(
          
          <CaseStudycards  item={item}/>
          ))
        }
        </div>
    </CourseTemp>
   </Fragment>
  )
}

export default CaseStudy