
import { Link } from "react-router-dom";

const subTitle = "Contest Prep Academy";
const title = "Key Features";


const categoryList = [
    {
        imgUrl: require('../../assets/images/category/icon/07.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'MOST ELABORATED CONTEST PREP COURSE EVER. &  PODCAST   CASE STUDIES ', 
    },
    {
        imgUrl: require('../../assets/images/category/icon/11.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'EVERY ASPECT COVERED FOR FEMALE BODY BUILDING.'
        ,
        desc: '27 Course',
    },
 
    {
        imgUrl: require('../../assets/images/category/icon/12.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'SIMPLE & EASY TO GRASP PURE APPLIACTION IN FIELD.',
        desc: '87 Course',
    },
    {
        imgUrl: require('../../assets/images/category/icon/10.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'SEPRATE ENGLISH/HINDI LECTURES & FREE COURSE UPDATES.',
        desc: '',
    },
    {
        imgUrl: require('../../assets/images/category/icon/08.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'INTERACTIVE FORUM SECTION & PRIVATE LIVE ',
        desc: '',
    },
 

    {
        imgUrl: require('../../assets/images/category/icon/09.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'TEAM HEAVY DUTY PREP PROTOCOLS & DISCUSSION OF IMPORTANT PEAKS.',
        desc: '',
    },
    {
        imgUrl: require('../../assets/images/category/icon/13.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'LIFE TIME ACCESSS. ATHLETE GUIDANCE &  LIVE QUESTIONS  ANSWERS ',
        desc: '15 Course',
    },
   
 

    {
        imgUrl: require('../../assets/images/category/icon/09.jpg'),
        imgAlt: 'category rajibraj91 rajibraj',
        title: 'COVERED EVERY ASPECTS OF NUTRITION & TRAINING IN DETAILS',
        desc: '',
    },

]

const CategoryTwo = () => {
    return (
        <div style={{paddingTop: '110px' , paddingBottom: '110px'}} className="category-section padding-tb section-bg style-2">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                        </div>
                                        <div className="category-content">
                                           <h6>{val.title}</h6>
                                            {/* <span>{val.desc}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CategoryTwo;