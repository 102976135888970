/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import "./Vlog.css";
import CourseTemp from "../Courses/CourseTemp";
import VlogBody from "./VlogBody";
import { auth, db } from "../../firebase/Firebase";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Vlog = () => {
  const user = auth.currentUser;
  const [memebrship, setMembership] = useState();

  useEffect(() => {
    getDoc(
      doc(
        db,
        "usersLesson",
        user?.uid,
        "MemberShip",
        "ContestPrepCertification"
      )
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setMembership(docSnap.data());
      }
    });
  }, [user?.uid]);

  const navigate = useNavigate();

  useEffect(() => {
    if (memebrship?.Subscription === "VIP") {
      if (memebrship?.unlock === false) {
        toast.error("Vlogs Section is Locked");
        navigate("/Courses");
      }
    } else if (memebrship?.Subscription === "Regular") {
      toast.error("Vlogs Section is Locked");
      navigate("/Courses");
    }
  }, [memebrship]);

  return (
    <Fragment>
      <CourseTemp>
        <VlogBody />
      </CourseTemp>
    </Fragment>
  );
};

export default Vlog;
