/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { auth, db } from "../../firebase/Firebase";
import { useState } from "react";
import { IoIosNotifications } from "react-icons/io";

import { RxCross1 } from "react-icons/rx";

const CourseTemp = ({ children }) => {
  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState(false);

  const showMenuBox = () => {
    setOpenNav(true);
    document.getElementById("");
  };
  const closeMenuBox = () => {
    setOpenNav(false);
  };

  /*==================== LINK ACTIVE ====================*/
  const linkColor = document.querySelectorAll(".nav__link");

  function colorLink() {
    linkColor.forEach((l) => l.classList.remove("active"));
    this.classList.add("active");
  }

  linkColor.forEach((l) => l.addEventListener("click", colorLink));

  const { pathname } = useLocation();
  const { userLogout } = useContext(AuthContext);

  const handleLogout = () => {
    userLogout()
      .then((result) => {
        // toast.success("Successfully Logout!");
        toast.success("Successfully Logout!", {
          position: "top-center",
        });
      })
      .catch((error) => console.error(error));
  };

  const [data, setData] = useState();
  const [check, setCheck] = useState();

  const uid = auth?.currentUser.uid;
  useEffect(() => {
    getDoc(doc(db, "usersLesson", uid)).then((docSnap) => {
      if (docSnap.exists()) {
        var str = docSnap.data().name;
        var matches = str.match(/\b(\w)/g);
        setData(matches.join(""));
        setCheck(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [uid]);

  const handleLogout1 = () => {
    userLogout()
      .then((result) => {
        // toast.success("Successfully Logout!");
        toast.error(`You don't have Login Permission`, {
          position: "top-center",
        });
      })
      .catch((error) => console.error(error));
  };

  const [sub, setSub] = useState();

  useEffect(() => {
    getDoc(
      doc(db, "usersLesson", uid, "MemberShip", "ContestPrepSubscription")
    ).then((docSnap) => {
      if (docSnap.exists()) {
        //    console.log(docSnap?.data().razorpay_subscription_id)

        setSub({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    });
  }, [uid]);

  useEffect(() => {
    if (check?.loginPermission === false || sub?.loginPermission === false) {
      handleLogout1();
    }
  }, [check, sub]);

  // let getnotifi = [];

  const [getnotifi, setgetnotifi] = useState();

  useEffect(() => {
    const q = query(
      collection(db, "usersLesson", uid, "Notification"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setgetnotifi(cities);
    });

    return () => unsubscribe();
  }, [uid]);

  const markasreadnoti = async (id) => {
    await deleteDoc(doc(db, "usersLesson", uid, "Notification", id));
  };

  return (
    <div className="CoursesBody">
      <header class="header">
        <div class="header__container">
          <div className="headersideTop">
            <p
              // src={require("../../assets/images/author/01.jpg")}
              alt=""
              class="header__img"
            >
              {data}
            </p>
            <div className="bodys">
              <details
                className={
                  getnotifi?.length === 0 ? "dropdown1" : "dropdown1 ho-ull "
                }
              >
                <summary role="button">
                  <div class="my-btn">
                    <div class="my-btn-border"></div>
                    <IoIosNotifications color="white" size={22} />
                    {getnotifi?.length === 0 ? (
                      <></>
                    ) : (
                      <>
                        {getnotifi?.length > 4 ? (
                          <span className="notifcationbadge">
                            5+
                            {/* {getnotifi?.length} */}
                          </span>
                        ) : (
                          <span className="notifcationbadge">
                            {getnotifi?.length}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </summary>
                <ul>
                  {getnotifi?.length === 0 ? (
                    <>
                      <li className="gap-sv">
                        <div className="gap-svg-h1">No Notification</div>
                      </li>
                    </>
                  ) : (
                    <>
                      {Array.isArray(getnotifi)
                        ? getnotifi.map((item, key) => {
                            return (
                              <li
                               
                                className="gap-sv"
                                key={key}
                              >
                                <div
                                 onClick={() =>
                                  item?.type === "type1"
                                    ? navigate(item?.path, { replace: true })
                                    : navigate(
                                        "/QuestionReply",

                                        {
                                          replace: true,
                                          state: {
                                            chapter: item?.chapter,
                                            item1: item?.item1,
                                            item2: item?.item2,
                                          },
                                        }
                                      )
                                }
                                className="gap-svg-h1">
                                  {item?.Notification}
                               
                                </div>
                                <RxCross1
                                    className="markasrednotifi"
                                    color="red"
                                    onClick={() => markasreadnoti(item.id)}
                                    size={20}
                                  />
                              </li>
                            );
                          })
                        : null}
                    </>
                  )}
                </ul>
              </details>
            </div>
            {/* <div class="my-btn">
              <div class="my-btn-border"></div>
              <IoIosNotifications color="white" size={22} />
            </div> */}
          </div>

          <Link to="/Courses">
            <img src={require("../../assets/images/logo/01.png")} alt="logo" />
          </Link>

          {/* <div class="header__search">
            <input type="search" placeholder="Search" class="header__input" />
            <i class="bx bx-search header__icon"></i>
          </div> */}

          {openNav === true ? (
            <>
              <div onClick={() => closeMenuBox()} class="header__toggle">
                <i
                  style={{ fontSize: "40px" }}
                  // onClick={() => showMenu("header-toggle", "navbar")}
                  class="bx bx-x"
                  id="header-toggle"
                ></i>
              </div>
            </>
          ) : (
            <>
              <div onClick={() => showMenuBox()} class="header__toggle">
                <i
                  style={{ fontSize: "40px" }}
                  // onClick={() => showMenu("header-toggle", "navbar")}
                  class="bx bx-menu"
                  id="header-toggle"
                ></i>
              </div>
            </>
          )}
        </div>
      </header>

      <div class={openNav === true ? "nav show-menu" : "nav"} id="navbar">
        <nav class="nav__container">
          <div>
            <a id="anchora12345" class="nav__link nav__logo">
              <i class="nav__icon"></i>
              <span class="nav__logo-name"></span>
            </a>

            <div class="nav__list">
              <div class="nav__items">
                <h3 id="h3333333333" class="nav__subtitle">
                  Profile
                </h3>

                <Link
                  to={"/Courses"}
                  id="anchora12345"
                  class={
                    pathname === "/Courses"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bx-home nav__icon"></i>
                  <span class="nav__name">Home</span>
                </Link>
                <Link
                  id="anchora12345"
                  to={"/Modules"}
                  class={
                    pathname === "/Modules"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bx-book-content  nav__icon"></i>
                  <span class="nav__name">Modules</span>
                </Link>
                <Link
                  to={"/Forums"}
                  id="anchora12345"
                  href="#"
                  class={
                    pathname === "/Forums" ? "nav__link activenav" : "nav__link"
                  }
                >
                  <i class="bx bx-message-rounded nav__icon"></i>
                  <span class="nav__name">Forums</span>
                </Link>

                <Link
                  to={"/Profile"}
                  id="anchora12345"
                  href="#"
                  class={
                    pathname === "/Profile"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bx-user nav__icon"></i>
                  <span class="nav__name">Profile</span>
                </Link>
                <Link
                  to={"/Vlogs"}
                  id="anchora12345"
                  class={
                    pathname === "/Vlogs" ? "nav__link activenav" : "nav__link"
                  }
                >
                  <i class="bx bxs-camera-movie  nav__icon"></i>
                  <span class="nav__name">Vlogs</span>
                </Link>
                <Link
                  to={"/CaseStudy"}
                  id="anchora12345"
                  class={
                    pathname === "/CaseStudy"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bxs-user-account  nav__icon"></i>
                  <span class="nav__name">CaseStudy</span>
                </Link>
                <Link
                  to={"/LiveStream"}
                  id="anchora12345"
                  class={
                    pathname === "/LiveStream"
                      ? "nav__link activenav"
                      : "nav__link"
                  }
                >
                  <i class="bx bxs-webcam  nav__icon"></i>
                  <span class="nav__name">Live Stream</span>
                </Link>
              </div>

              <div class="nav__items">
                {/* <h3 id="h3333333333" class="nav__subtitle">
                  Coming Soon
                </h3>
                 */}

                {/* <div class="nav__dropdown">
                  <a id="anchora12345" href="#" class="nav__link">
                    <i class="bx bx-bell nav__icon"></i>
                    <span class="nav__name">Notifications</span>
                    <i class="bx bx-chevron-down nav__icon nav__dropdown-icon"></i>
                  </a>

                  <div class="nav__dropdown-collapse">
                    <div class="nav__dropdown-content">
                      <a id="anchora12345" href="#" class="nav__dropdown-item">
                        Blocked
                      </a>
                      <a id="anchora12345" href="#" class="nav__dropdown-item">
                        Silenced
                      </a>
                      <a id="anchora12345" href="#" class="nav__dropdown-item">
                        Publish
                      </a>
                      <a id="anchora12345" href="#" class="nav__dropdown-item">
                        Program
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* <a onClick={() => toast.error('Coming Soon')}  id="anchora12345"  class="nav__link">
                  <i class="bx bxs-user-account  nav__icon"></i>
                  <span class="nav__name">Case Study</span>
                </a> */}
                {/* <a
                  onClick={() => toast.error("Coming Soon")}
                  id="anchora12345"
                  class="nav__link"
                >
                  <i class="bx bxs-webcam  nav__icon"></i>
                  <span class="nav__name">Live Stream</span>
                </a> */}
              </div>
            </div>
          </div>

          <a
            id="anchora12345"
            onClick={() => handleLogout()}
            class="nav__link nav__logout"
          >
            <i class="bx bx-log-out nav__icon"></i>
            <span class="nav__name">Log Out</span>
          </a>
        </nav>
      </div>
      {children}
    </div>
  );
};

export default CourseTemp;
