import React, { Fragment, useState } from "react";
import "./CaseStudy.css";
import CourseTemp from "../Courses/CourseTemp";

import { useEffect } from "react";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import ScrollContainer from "react-indiana-drag-scroll";
import { useLocation, useParams } from "react-router-dom";
import { db } from "../../firebase/Firebase";
import ReactPlayer from "react-player";

import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

import Accordition5 from "./Accordition5";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slidebox from "./Slidebox";

const CaseStudyDetails = () => {
  const { state } = useLocation();
  const { detail } = state;

  const { id } = useParams();

  const [getdata, setGetData] = useState();
  const [video, setVideo] = useState("");

  useEffect(() => {
    try {
      const q = query(
        collection(db, `CaseStudy/${detail}/${detail}/${id}/Image`),
        orderBy("createdAt", "asc")
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const cities = [];
        querySnapshot.forEach((doc) => {
          cities.push({ id: doc.id, ...doc.data() });
        });
        setGetData(cities);
      });

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  }, [detail, id, getdata]);

  const [lang1, setLang1] = useState();

  useEffect(() => {
    if (video) {
      setLang1("Video");
    } else {
      setLang1("Image");
    }
  }, [video]); // Use [video] as the dependency, not [lang1]

  useEffect(() => {
    try {
      getDoc(doc(db, `CaseStudy/${detail}/${detail}/${id}/Video/Video`)).then(
        (docSnap) => {
          if (docSnap.exists()) {
            setVideo({ id: docSnap.id, ...docSnap.data() });
          } else {
            setVideo(null);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  }, [detail, id]);

  return (
    <Fragment>
      <CourseTemp>
        <h1 className="heading-case3">Athlete Case Studies,</h1>
        <h1 className="heading-case2">Video and Diet Chart Images.</h1>

        {/* <div className="section-case-d1">
          <div className="section-case-d2">
            {lang1 === "Video" ? (
              <>
                {video === null ? (
                  <></>
                ) : (
                  <>
                
                    <iframe
                          src={video?.url}
                          style={{
                            border: 0,
                            height: "500px",
                            width: "100%",
                            maxWidth: "100%",
                            borderRadius: "5px",
                          }}
                          allowFullScreen="true"
                          allow="encrypted-media"
                          title="ContestPrepAcademy"
                        ></iframe>
                  </>
                )}
              </>
            ) : (
              <>
                {getdata && (
                  <>
                    <div className="lh-array-1img">
                      <img src={getdata[0]?.img} alt="" key={getdata[0]?.id} />
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          <div className=" case-side-rh">
            <div className="case-btn-choose">
              <button
                className={lang1 === "Video" ? "bold-btn-text " : ""}
                onClick={() => {
                  setLang1("Video");
                }}
              >
                Video
              </button>
              <button
                className={lang1 === "Image" ? "bold-btn-text " : ""}
                onClick={() => {
                  setLang1("Image");
                }}
              >
                Images
              </button>
            </div>
            <div>
            {lang1 === "Video" ? (
            <>
             
            </>
          ) : (
            <>
              <Slidebox getdata={getdata} />
           
            </>
          )}
            </div>
          </div>
        </div> */}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="course-view">
                <div className="row justify-content-center">
                  <div className="col-lg-8 col-12">
                    <div className="video-part mb-4 mb-lg-0">
                      <div className="vp-title ">
                        <h3 className="headingchaptername"></h3>
                      </div>
                      <div className="vp-video">
                        {lang1 === "Video" ? (
                          <>
                            {video === null ? (
                              <></>
                            ) : (
                              <>
                                <ReactPlayer
                                style={{ borderRadius: "5px" }} 
                                  className="reactplayerVlog"
                                  light={require("../../assets/images/bg-module.jpg")}
                                  width={"100%"}
                                  height={"500px"}
                                  url={video?.url}
                       
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  allowFullScreen
                                  controls
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {getdata && (
                              <>
                                <div
                                  className={getdata ? " " : "lh-array-1img"}
                                >
                                  <img
                                    src={getdata[0]?.img}
                                    alt=""
                                    key={getdata[0]?.id}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-8 col-12 ">
                    <div className="video-list-area">
                      <div className="video-head"></div>
                      <div className="video-body">
                        <div className="course-select-area border-radius-12">
                          <div className=" case-side-rh">
                            <div className="case-btn-choose">
                              <button
                                className={
                                  lang1 === "Video" ? "bold-btn-text " : ""
                                }
                                onClick={() => {
                                  setLang1("Video");
                                }}
                              >
                                Video
                              </button>

                              <button
                                className={
                                  lang1 === "Image" ? "bold-btn-text " : ""
                                }
                                onClick={() => {
                                  setLang1("Image");
                                }}
                              >
                                Images
                              </button>
                            </div>
                            <div>
                              {lang1 === "Video" ? (
                                <>
                                  {video ? (
                                    ""
                                  ) : (
                                    <h1 className="video-av-not">
                                      Video Not Available
                                    </h1>
                                  )}
                                </>
                              ) : (
                                <>
                                  {getdata?.length === 0 ? (
                                    <h1 className="video-av-not">
                                      Images Not Available
                                    </h1>
                                  ) : (
                                    <Slidebox getdata={getdata} />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CourseTemp>
    </Fragment>
  );
};

export default CaseStudyDetails;
