/* eslint-disable jsx-a11y/anchor-is-valid */

import { Link } from "react-router-dom";

const subTitle = "Featured Courses";
const title = "Pick A Course To Get Started";

const courseList = [
  {
    imgUrl: require("../../assets/images/course/07.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹30k",
    cate: "Regular Membership",
    reviewCount: "",
    title: "Contest Prep Academy Regular",
    totalLeson: "39 Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/CourseDetailStandard",
  },
  {
    imgUrl: require("../../assets/images/course/08.jpg"),
    imgAlt: "course rajibraj91 rajibraj",
    price: "₹50k",
    cate: "VIP Membership",
    reviewCount: "",
    title: "Contest Prep Academy VIP",
    totalLeson: "39 Lesson",
    schdule: "Online Class",
    authorImgUrl: require("../../assets/images/course/author/40.jpg"),
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Atinderjeet Singh",
    btnText: "Read More",
    link: "/CourseDetailPremium",
  },
];

const CourseTwo = () => {
  return (
    <div
      className="course-section padding-tb"
      style={{ paddingTop: "110px", paddingBottom: "110px" }}
    >
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-lg-2 row-cols-1">
            {courseList.map((val, i) => (
              <div className="col" key={i}>
                <div className="course-item style-2">
                  <div className="course-inner">

                  <div className="course-thumb">
                  <Link to={`${val.link}`}>
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      <div className="course-price">{val.price}</div>
                      </Link>
                    </div>
                 
                    <div className="course-content">
                      <div className="course-category">
                        <div className="course-cate">
                          <a href="#">{val.cate}</a>
                        </div>
                        <div className="course-reiew">
                          {/* <Rating /> */}
                          <span className="ratting-count">
                            {val.reviewCount}
                          </span>
                        </div>
                      </div>
                      <Link to={`${val.link}`}>
                        <h4>{val.title}</h4>
                      </Link>
                      <div className="course-details">
                        <div className="couse-count">
                          <i className="icofont-video-alt"></i> {val.totalLeson}
                        </div>
                        <div className="couse-topic">
                          <i className="icofont-signal"></i> {val.schdule}
                        </div>
                      </div>
                      <div className="course-footer">
                        <div className="course-author">
                          <img
                            src={`${val.authorImgUrl}`}
                            alt={`${val.authorImgAlt}`}
                          />
                          <Link to={`${val.link}`} className="ca-name">
                            {val.authorName}
                          </Link>
                        </div>
                        <div className="course-btn">
                          <Link to={`${val.link}`} className="lab-btn-text">
                            {val.btnText}{" "}
                            <i className="icofont-external-link"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTwo;
