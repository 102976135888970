
// import Rating from "../sidebar/rating";

const title = "Contest Prep Academy";
const desc = "";
const author = "Atinderjeet Singh";
const reviewCount = "";
const videoLink = "https://firebasestorage.googleapis.com/v0/b/contestprepacademy-ef094.appspot.com/o/Intro-video%2Fhindi%2Fvideo%20hindi%201.mp4?alt=media&token=934697f5-ae8e-4b7d-a82c-4483551efa18";


const categoryList = [
    {
        link: '#',
        text: 'Contest Prep Academy',
        className: 'course-cate',
    },
    {
        link: '#',
        text: 'VIP Membership',
        className: 'course-offer', 
    },
]


const PageHeaderTwo2 = () => {
    return (
        <div className="pageheader-section style-2">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
                    <div className="col-lg-7 col-12">
                        <div className="pageheader-thumb">
                            <img src={'https://firebasestorage.googleapis.com/v0/b/contestprepacademy-ef094.appspot.com/o/About-Mentors%2FAtinderjeet-Thumbnail.png?alt=media&token=bd40dfdd-5c14-4851-b0c8-e763a0788cbb'} alt="rajibraj91" className="w-100" />
                            <a href={videoLink} className="video-button popup" target="_blank"><i className="icofont-ui-play"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-5 col-12">
                        <div className="pageheader-content">
                            <div className="course-category">
                                {categoryList.map((val, i) => (
                                    <a href={val.link} className={val.className} key={i}>{val.text}</a>
                                ))}
                            </div>
                            <h2 className="phs-title">{title}</h2>
                            <p className="phs-desc">{desc}</p>
                            <div className="phs-thumb">
                                <img src={require('../../assets/images/course/author/40.jpg')} alt="rajibraj91" />
                                <span>{author}</span>
                                <div className="course-reiew">
                                    {/* <Rating /> */}
                                    <span className="ratting-count">{reviewCount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default PageHeaderTwo2;