/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import "./Chapter.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState } from "react";
import { useEffect } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { AiFillPlayCircle } from "react-icons/ai";
import QuestionSlider from "./QuestionSlider";
import { useNavigate } from "react-router-dom";
import { AiOutlineSend } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-modal";
import { toast } from "react-hot-toast";
import { addDoc, doc, getDoc, increment, updateDoc } from "firebase/firestore";
import { storage } from "../../firebase/Firebase";
import moment from "moment";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const ChapterBody = ({ item }) => {
  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user?.uid]);

  const swiperRef = useRef(null);

  const [toggleState, setToggleState] = useState(0);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [data, setData] = useState();
  const [exist, setExist] = useState(false);

  const [swiper, setSwiper] = useState();

  const [module, setModule] = useState();

  const [sliderload, setSliderLoad] = useState(false);

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy", item?.id, item?.id);
    const q = query(todosref, orderBy("num", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
        setSliderLoad(true);
        setChapterData(Blog[0]);
      });
    });

    return () => unsub();
  }, [item?.id]);

  const [chapterData, setChapterData] = useState();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");

  const fileTypes = ["JPEG", "PNG"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
    // document.getElementsByClassName('dropdown').style.zIndex = "1"
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  }

  const addDiscussion = async () => {
    if (file) {
      const uploadImg = async () => {
        const imgRef = ref(
          storage,
          `forums/${new Date().getTime()} - ${file.name}`
        );
        try {
          const snap = await uploadBytes(imgRef, file);
          const url = await getDownloadURL(ref(storage, snap.ref.fullPath));

          await addDoc(collection(db, "Forum"), {
            Image: url,
            name: name,
            description,
            tags,
            user: getUser?.name,
            email: getUser?.email,
            verified: "pending",
            createdAt: moment().format(),
            replies: 0,
            lastreply: "0",
            userId: user.uid,
          });
          addDoc(collection(db, "usersLesson", getUser.uid, "Notification"), {
            Notification: `Your Forum's Approval is Pending`,
            markasRead: false,
          });
          updateDoc(doc(db, "usersLesson", getUser.uid), {
            NotificationNumber: increment(1),
          })
            .then(() => {
              toast("Forum will posted after approval", {
                duration: 2000,
                position: "top-center",
                // icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              toast("Forum Added", {
                duration: 2000,
                position: "top-center",
                icon: "✔",
                style: {
                  borderRadius: "10px",
                  background: "#3499EE",
                  color: "#fff",
                },
              });
              closeModal();
            })
            .then(() => {
              setName("");
              setDescription("");
              setFile();
              setTags("");
            });
        } catch (err) {
          console.log(err);
        }
      };
      uploadImg();
    } else {
      await addDoc(collection(db, "Forum"), {
        name: name,
        description,
        tags,
        user: getUser?.name,
        email: getUser?.email,
        verified: "pending",
        createdAt: moment().format(),
        replies: 0,
        lastreply: "0",
        userId: user.uid,
      });
      addDoc(collection(db, "usersLesson", getUser.uid, "Notification"), {
        Notification: `Your Forum's Approval is Pending`,
        markasRead: false,
      });
      updateDoc(doc(db, "usersLesson", getUser.uid), {
        NotificationNumber: increment(1),
      })
        .then(() => {
          toast("Forum will posted after approval", {
            duration: 2000,
            position: "top-center",
            // icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          toast("Question Added", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          setName("");
          setDescription("");
          setFile();
          setTags("");
        });
    }
  };

  return (
    <div className="ChapterBody">
      <div className="ChapterBodyTop">
        <h1 className="ChapterBodyToph1">Contest Prep Academy</h1>
        <h2 className="ChapterBodyToph2">
          {item.Module} <br />
          {item.NAME}
        </h2>
      </div>
      <div className="ChapterBodyMiddle">
        <Tabs>
          <TabList className="ChapterTabsMain">
            {module?.map((item, key) => (
              <Tab
                key={key}
                onClick={() => {
                  toggleTab(key);
                  setLoading(true);
                  // console.log(swiperRef.current.swiper.activeIndex)
                  swiper.slideTo(key, 1000);
                  setChapterData(item);
                  setExist(false);
                  setData([]);
                }}
              >
                <div
                  className={
                    toggleState === key ? "TabButton" : "TabButtonClick"
                  }
                >
                  <p
                    className={
                      toggleState === key ? "TabButtonp1" : "TabButtonp11"
                    }
                  >
                    Chapter
                  </p>
                  <p
                    className={
                      toggleState === key ? "TabButtonp2" : "TabButtonp22"
                    }
                  >
                    {item?.chapter}
                  </p>
                  <p
                    className={
                      toggleState === key ? "TabButtonp3" : "TabButtonp33"
                    }
                  >
                    .
                  </p>
                </div>
              </Tab>
            ))}
          </TabList>
          <Swiper
            ref={swiperRef}
            centeredSlides={true}
            onSwiper={(swiper) => setSwiper(swiper)}
            slidesPerView={"auto"}
            spaceBetween={50}
            // loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            navigation={true}
            className="mySwiper"
            onSlideChange={(swiper) => {
              toggleTab(swiper.activeIndex);
              setChapterData(module[swiper.activeIndex]);
              setLoading(true);
              setData([]);
              setExist(false);
            }}
          >
            <TabPanel>
              {module?.map((data, key) => (
                <Tabs key={key}>
                  <SwiperSlide className="swiperslide1111" key={key}>
                    {({ isActive }) => (
                      <>
                        {isActive ? (
                          <>
                            <div
                              onClick={() =>
                                navigate("/Lectures", {
                                  state: { chapter: data, item: item },
                                })
                              }
                              className={"VideoSlide1"}
                            >
                              <div className={"VideoSlideTop"}>
                                <h1>{data?.name}</h1>
                              </div>
                              <div className={"VideoSlideMiddle"}>
                                <div className="VideoSldieTopCard">
                                  <div className="Playbutton">
                                    <AiFillPlayCircle color="white" size={70} />
                                  </div>
                                </div>
                                <div className="sliderbar">
                                  <div className="sliderbarspan"></div>
                                  <span className="sliderbarspan1">{data.Htime}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => swiper.slideTo(key, 1000)}
                              className={"VideoSlide2"}
                            >
                              <div className={"VideoSlideTop2"}>
                                <h1>{data?.name}</h1>
                              </div>
                              <div className={"VideoSlideMiddle2"}>
                                <div className="VideoSldieTopCard1">
                                  <div className="Playbutton1">
                                    <AiFillPlayCircle color="white" size={60} />
                                  </div>
                                </div>
                                <div className="sliderbar112">
                                  <h1>{item?.name}</h1>
                                </div>
                                <div className="sliderbar1">
                                  <div className="sliderbarspan11"></div>
                                  <span className="sliderbarspan112">{data.Htime}</span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </SwiperSlide>
                </Tabs>
              ))}
            </TabPanel>
          </Swiper>
        </Tabs>
      </div>
      <div className="ChapterBodyBottom">
        <div className="ChapterBodyBottomBackground">
          <div className="ChapterBodyBottomBackgroundLeft"></div>
          <div className="ChapterBodyBottomBackgroundRight"></div>
          <div className="QUestionsCardsEction">
            <div className="AddQuestionCard">
              <div className="QuestionCardTop">
                <div className="QuestionCardTopCircle"></div>
                <div className="QuestionCardTopText">
                  <h1>Ask any Question?</h1>
                </div>
              </div>
              <div className="QuestionCardMiddle">
                <div className="QuestionCardMiddleButton">
                  <button onClick={openModal}>New Question</button>
                </div>
                <ul className="QuestionCardMiddleUL">
                  <li className="QuestionCardMiddleLI">
                    <span>DROP ALL YOUR LECTURE RELATED QUESTIONS HERE</span>
                  </li>
                  <li className="QuestionCardMiddleLI">
                    <span>ONLY DROP YOUR LECTURE SPECIFIC QUESTIONS HERE</span>
                  </li>
                </ul>
              </div>
            </div>
            <QuestionSlider
              exist={exist}
              setExist={setExist}
              data={data}
              setData={setData}
              sliderload={sliderload}
              chapterData={chapterData}
              item={item}
              name="QuestionCardSection"
            />
          </div>
        </div>
        <div className="ChapterBodyBottomBody">
          <QuestionSlider
            exist={exist}
            setExist={setExist}
            data={data}
            setData={setData}
            sliderload={sliderload}
            chapterData={chapterData}
            item={item}
            name="QuestionCardSection2"
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className="header-modal-s">
          <div className="search-input">
            <input
              placeholder="Discussion Title"
              type="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="name"
              required
            />
          </div>
        </div>

        <hr />
    
        <div>
          <div className="modal-text-area-data-1">
            <div className="text-area-modal-s">
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="text-modal-area1"
                placeholder="Type your discussion content here"
                name=""
                id=""
                cols="30"
                rows="10"
                required
              ></textarea>
            </div>
            <div className="upload-options">
              {/* <input type="file" name="" id="" /> */}
              <FileUploader
                classes="drop_area"
                multiple={false}
                handleChange={handleChange}
                name="file"
                types={fileTypes}
              />
            </div>
          </div>
          <hr />
          <div className="section-1-text">
            <textarea
              value={tags}
              onChange={(e) => setTags(e.target.value)}
              placeholder="Type one or more tag, comma separated "
              className="text-area-modal-2"
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <hr />
          <div className="check-box-post-btn">
            <div className="check-box-post-btn1">
              <button
                disabled={!name || !description}
                onClick={() => addDiscussion()}
                className="post-btn-dis"
              >
                Send <AiOutlineSend />
              </button>
              <button onClick={closeModal} className="post-btn-dis">
                Cancel <MdCancel />
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="ChapterBodyFotter"></div>
    </div>
  );
};

export default ChapterBody;
