import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import Respond from "../../component/sidebar/respond";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";

import PageHeaderTwo2 from "../../component/layout/pageheader22";
import CourseSideDetail2 from "../../component/sidebar/course-detail2";

const Term = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <Fragment>
      <HeaderTwo />

      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-single-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3 className=" text-center p-5">Term Conditions</h3>
                      <div>
                        <p className="term-p">
                          Welcome to Contest Prep Academy, operated by Delta
                          Infox! Before you embark on your bodybuilding journey
                          with us, please carefully read and understand the
                          terms and conditions outlined below:
                        </p>
                        <br />
                        <p className="term-p">
                          1. Acceptance of Terms:
                          <br />
                          <br />
                          By enrolling in Contest Prep Academy, you acknowledge
                          that you have read, understood, and agree to comply
                          with these terms and conditions. If you do not agree
                          with any part of these terms, please refrain from
                          accessing the course.
                        </p>
                        <br />
                        <p className="term-p">
                          2. Eligibility:
                          <br />
                          <br />
                          Contest Prep Academy is designed for individuals aged
                          18 and above. By enrolling in our course, you confirm
                          that you meet this age requirement.
                        </p>
                        <br />
                        <p className="term-p">
                          3. Enrollment Process:
                          <br />
                          <br />
                          To enroll in Contest Prep Academy, you must complete
                          the registration process accurately. You are
                          responsible for providing current and correct
                          information during registration.
                        </p>
                        <br />

                        <p className="term-p">
                          4. Payment and Fees:
                          <br />
                          <br />
                          Payment is required at the time of enrollment. Delta
                          Infox reserves the right to adjust course fees at its
                          discretion. All payments are non-refundable.
                        </p>
                        <br />

                        <p className="term-p">
                          5. Course Access:
                          <br />
                          <br />
                          Upon successful enrollment and payment, you will be
                          granted access to the course content. This access is
                          personal and non-transferable.
                        </p>

                        <br />

                        <p className="term-p">
                          6. Intellectual Property:
                          <br />
                          <br />
                          All materials provided in Contest Prep Academy,
                          including videos, documents, and images, are the
                          intellectual property of Delta Infox. Unauthorized
                          reproduction, distribution, or use of these materials
                          is strictly prohibited.
                        </p>
                        <br />

                        <p className="term-p">
                          7. Conduct:
                          <br />
                          <br />
                          Participants are expected to conduct themselves
                          professionally and respectfully. Delta InfoX reserves
                          the right to terminate access to the course without a
                          refund if a participant engages in disruptive behavior
                          or violates any rules of conduct.
                        </p>
                        <p className="term-p">
                          8. Disclaimer:
                          <br />
                          <br />
                          The information presented in Contest Prep Academy is
                          for educational purposes only. Delta Infox does not
                          provide medical, fitness, or nutritional advice. It is
                          advisable to consult with a qualified professional
                          before making significant changes to your exercise or
                          dietary routine.
                        </p>
                        <p className="term-p">
                          9. Modifications:
                          <br />
                          <br />
                          Delta Infox retains the right to modify, suspend, or
                          discontinue any aspect of Contest Prep Academy,
                          including course content, without prior notice.
                        </p>
                        <p className="term-p">
                          10. Privacy:
                          <br />
                          <br />
                          Your privacy is important to us. Please refer to our
                          Privacy Policy for details on how we collect, use, and
                          safeguard your personal information.
                        </p>
                        <p className="term-p">
                 
By enrolling in Contest Prep Academy, you affirm your understanding and acceptance of these terms and conditions.

                        </p>



<br />
<p className="term-p">
                 
If you have any questions or concerns, please contact us at info@deltainfox.com

                 
                                         </p>
                                         <br />
                                         <p className="term-p">
                 
                                         Thank you for choosing Contest Prep Academy!


                 
                                         </p>
                                         <br />


<p>
  
Delta Infox
</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Term;
