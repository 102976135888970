/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const HeaderTwo = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  return (
    <header
      className={`header-section style-2 ${
        headerFiexd ? "header-fixed fadeInUp" : ""
      }`}
    >
      <div className="header-bottom">
        <div className="header-wrapper">
          <div className="logo-search-acte">
            <div className="logo">
              <Link to="/">
                <img
                  src={require("../../assets/images/logo/01.png")}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="cate-search d-none d-xxl-block">
              <div className="d-flex flex-wrap align-items-center">
                <div className="header-cate">
                  {/* <SelectCatagory select={'all'} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="menu-area">
            <div className="menu">
              <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li className="menu-item-has-children">
                                    <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Courses</a>
                                    <ul className="lab-ul dropdown-menu">
                                  
                                        <li><NavLink to="/CourseDetailStandard">Regular Membership</NavLink></li>
                                        <li><NavLink to="/CourseDetailPremium">VIP Membership</NavLink></li>
                                    </ul>
                                </li>

          
                 <li className="menu-item-has-children">
                                    <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Policy</a>
                                    <ul className="lab-ul dropdown-menu">
                                  
                                        <li><NavLink to="/Terms&Conditions"> Terms & Conditions</NavLink></li>
                                        <li><NavLink to="/PrivacyPolicy"> Privacy Policy</NavLink></li>
                                        <li><NavLink to="/Refund&CancellationPolicy"> Refund & Cancellation Policy</NavLink></li>
                                    </ul>
                                </li>
                           
                                <li>
                  <NavLink to="/Pricing">Pricing</NavLink>
                </li>
                <li>
                  <NavLink to="/About">About Us</NavLink>
                </li>     

                <li>
                  <NavLink to="/ContactUs">Contact</NavLink>
                </li>
                


               


                <li className="nav-li-n">

                  <NavLink to="/Login">Log in</NavLink>
                </li>
                
                <li className="nav-li-n">
                  <NavLink to="/SignUp">Sign up</NavLink>
                </li>
              </ul>
            </div>

            <Link to="/Login" className="login">
              <i className="icofont-user"></i> <span>LOG IN</span>{" "}
            </Link>
            <Link to="/SignUp" className="signup">
              <i className="icofont-users"></i> <span>SIGN UP</span>{" "}
            </Link>

            <div
              className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
              onClick={() => setMenuToggle(!menuToggle)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwo;
