import React from 'react'
import './Loader.css'

const Loader = () => {
  return (
   <div className="Loader123">
   <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
   </div>
  )
}

export default Loader