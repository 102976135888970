/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from "react";
import HeaderTwoAuth from "../../component/layout/header-2-auth";
import PageHeader from "../../component/layout/pageheader";
import {
  collection,
  doc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase/Firebase";
import Modal from "react-modal";
import logo from "../../assets/images/logo/png.png";
import Skill2 from "../../component/section/skill2";
import Compare from "../../component/section/Compare";
import { AuthContext } from "../../context/AuthContext";
import toast from "react-hot-toast";
import Compare3 from "../../component/section/Compare3";
import Loader from "../../component/sidebar/Loader2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const SubscriptionPage = () => {
  const { userLogout } = useContext(AuthContext);

  const handleLogout = () => {
    userLogout()
      .then((result) => {
        // toast.success("Successfully Logout!");
        toast.success("Please Login Again!", {
          position: "top-center",
        });
      })
      .catch((error) => console.error(error));
  };

  const [icon, setIcon] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "scroll";
  }

  const uid = auth.currentUser.uid;

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };


  const [load, setLoad] = useState(false);


  async function showRazorpay() {
    setLoad(true);

    try {
      const res = loadScript("https://checkout.razorpay.com/v1/checkout.js");
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        setLoad(false);
        return;
   

      }

      const data = await fetch(
        
        "https://us-central1-nodejs-f34dd.cloudfunctions.net/app/subscriptions",
        {
          method: "POST",
        }
      ).then((t) => t.json());
      setLoad(false);
      // console.log(data);

      const options = {
        key: "rzp_live_wCNesKyYmEdu3V",
        subscription_id: data.id,
        plan_id: data.plan_id,
        // order_id: data.id,
        name: "Contest Prep Subscription",
        description: "Contest Prep Monthly Subscription",
        // image: "http://localhost:6001/logo.svg",
        handler: async function (response) {
          // alert(response.razorpay_payment_id);
          // console.log(response);
          const Subscription = await fetch(
            `https://us-central1-nodejs-f34dd.cloudfunctions.net/app/subscriptions/${response.razorpay_subscription_id}`,
            {
              method: "POST",
            }
          ).then((t) => t.json());

          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          setDoc(
            doc(
              db,
              "usersLesson",
              uid,
              "MemberShip",
              "ContestPrepSubscription"
            ),
            {
              // payment_id: response.razorpay_payment_id,
              // subscription_id: response.razorpay_subscription_id,
              // signature: response.razorpay_signature,
              loginPermission: true,
              ...response,
              ...Subscription,
            }
          ).then(() => {
            handleLogout();
          });

          alert("Transaction successful");
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  }

 
    return (
      <Fragment>
{load === true && (

 <Loader />
)}

        <HeaderTwoAuth />
        <PageHeader title={"Subscription"} curPage={"Courses"} />
        <div
          style={{ paddingTop: "110px", paddingBottom: "110px" }}
          className="course-view-section padding-tb section-bg"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="course-view">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-12">
                      <div className="video-part mb-4 mb-lg-0">
                        <div className="vp-title mb-4">
                          <h3>Contest Prep Subscription</h3>
                        </div>

                        <div
                          className={`content-wrapper ${icon ? "open" : ""}`}
                        >
                          <div
                            className="content-icon d-lg-none"
                            onClick={() => setIcon(!icon)}
                          >
                            <i className="icofont-caret-down"></i>
                          </div>
                          <div className="vp-content mb-5">
                            <h4>Introduction</h4>
                            <p>
                              Welcome to our Bodybuilding Education Vlog Section
                              Subscription! <br />
                              In this exclusive subscription, we're dedicated to
                              delivering expert insights, practical tips, <br />{" "}
                              and in-depth knowledge on all things of
                              bodybuilding. Get ready to unlock the secrets of{" "}
                              <br /> bodybuilding Preps and all the insights on
                              diet, recovery, enhancements and much more <br />{" "}
                              as we guide you on the path to your ultimate
                              bodybuilding goals.
                            </p>
                          </div>
                        </div>
                        <Skill2 />
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-8 col-12">
                      <div className="video-list-area">
                        <div className="video-head"></div>
                        <div className="video-body">
                          <div className="course-select-area border-radius-12">
                            <div className="">
                              <div class="col-md-12 col-sm-6 mb-3">
                                <button
                                  onClick={showRazorpay}
                                  class="btn btn-success w-100"
                                >
                                  Enroll Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Compare />
      </Fragment>
    );
 
};

export default SubscriptionPage;
