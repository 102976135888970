/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import ScrollToTop from "../component/layout/ScrollToTop";

const Main = () => {
  const { user } = useContext(AuthContext);
  
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      navigate("/Home");
    }
  }, [user]);

  if (user) {
    return (
      <div>
        <ScrollToTop />
        <Outlet></Outlet>
      </div>
    );
  } else {
    return (
      <div>
        <ScrollToTop />
        <Outlet></Outlet>
      </div>
    );
  }


};

export default Main;
