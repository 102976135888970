import React , {Fragment} from 'react'
import CourseTemp from '../../Courses/CourseTemp'
import QuestionReplyBody from './QuestionReplyBody'

const QuestionReply = () => {
  return (
   <Fragment>
    <CourseTemp>
        <QuestionReplyBody />
    </CourseTemp>
   </Fragment>
  )
}

export default QuestionReply