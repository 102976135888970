/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "./style.css";
import Image1 from "../../assets/images/logo/Artboard2.jpg";
import Progress_bar from "../../component/sidebar/PercentLine";
import { useState } from "react";
import { auth, db } from "../../firebase/Firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const CourseBodyMain = () => {
  const [getUser, setGetUser] = useState();
  const uid = auth.currentUser.uid;

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "usersLesson", uid), (doc) => {
      setGetUser(doc.data());
    });

    return () => unsub();
  }, [uid]);

  const [Total, setTotal] = useState();

  useEffect(() => {
    getDoc(doc(db, "ContestPrepAcademy", "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        setTotal(docSnap.data().TotalModule);
      }
    });
  }, [uid]);

  const navigate = useNavigate();

  // console.log(moment().add(1, 'months').calendar())

  // console.log(moment())

  return (
    <main className="CourseBodyMain">
      <div className="CourseMainCard">
        <img className="CourseMainCardImg" src={Image1} alt="" />
        <div className="CourseMainCardBody">
          <h1>The Most Elaborated Bodybuilding Study</h1>
          <Progress_bar
            bgcolor="#bc0000"
            progress={(parseInt(getUser?.Module1) / Total) * 100}
            height={5}
          />
          <div>
            <h1 className="percentage-course-com">
              {((parseInt(getUser?.Module1) / Total) * 100).toFixed(2)}%
              Complete
            </h1>
          </div>
          <button
            onClick={() => navigate("/Modules")}
            class="button-10"
            role="button"
          >
            My Course
          </button>
        </div>
      </div>
    </main>
  );
};

export default CourseBodyMain;
