import React from "react";

const Accordition4 = ({ vlogs, id, onChangeLesson }) => {
  return (
    <>
      {vlogs?.map((item, key) => (
        <div
          onClick={() => onChangeLesson(item, key)}
          className="course-lists d-flex flex-wrap justify-content-between"
        >
          <div className="csa-left">
            <h6>
              {item?.num}. {item?.name}
            </h6>
            <p>
              <i className="icofont-play-alt-2"></i>
              {item?.time} Min
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default Accordition4;
