import React from 'react'
import './LiveStream.css'

const LiveStreamBody = () => {
  return (
 <div className="LiveStreamBody">

 </div>
  )
}

export default LiveStreamBody