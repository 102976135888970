import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import Respond from "../../component/sidebar/respond";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";

import PageHeaderTwo2 from "../../component/layout/pageheader22";
import CourseSideDetail2 from "../../component/sidebar/course-detail2";

const About2 = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <Fragment>
      <HeaderTwo />

      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-single-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">  
                      <h3 className=" text-center p-5">About Us</h3>
                      <div>
                        <p className="about-pp2">
               

Welcome to Contest Prep Academy, the ultimate destination for aspiring bodybuilders seeking comprehensive and expert guidance on competition preparation. Operated by Delta Infox, a leading name in online education, Contest Prep Academy is dedicated to empowering individuals to achieve their fitness goals and excel in the challenging world of competitive bodybuilding.
<br />
<br />

Our Mission:
<br />
<br />
At Contest Prep Academy, our mission is to provide a cutting-edge online learning platform that not only educates but inspires and motivates. We believe in equipping our students with the knowledge, skills, and mindset necessary to navigate the intricacies of contest preparation, leading them towards success on the stage.
<br />
<br />
Why Choose Contest Prep Academy?
<br />
<br />

Expert Guidance: Our courses are crafted by seasoned fitness professionals and bodybuilding experts who bring a wealth of practical experience and knowledge to the table.
<br />
<br />
Comprehensive Curriculum: Contest Prep Academy offers a comprehensive curriculum covering all aspects of contest preparation, including training techniques, nutrition strategies, posing, and mental conditioning.
<br />
<br />
Flexibility and Accessibility: We understand the importance of flexibility in your fitness journey. Our online courses are designed to fit seamlessly into your schedule, allowing you to learn at your own pace from the comfort of your home.
<br />
<br />
Cutting-Edge Resources: Access exclusive video content, training plans, nutritional guides, and more. Stay updated with the latest trends and insights in the world of bodybuilding.
<br />
<br />
Community Support: Join a community of like-minded individuals who share your passion for bodybuilding. Engage in discussions, seek advice, and build connections with fellow students.
<br />
<br />
Our Commitment to Excellence:
<br />
<br />
Delta InfoX is committed to delivering excellence in online education. With a focus on innovation, quality, and learner satisfaction, we continually strive to elevate the standards of fitness education. Contest Prep Academy is a testament to our dedication to providing a top-tier learning experience for fitness enthusiasts worldwide.
<br />
<br />
Contact Us:
<br />
<br />
Have questions or need assistance? Feel free to reach out to our dedicated support team at info@deltainfox.com. We are here to support you on your journey to becoming a successful and confident competitor.
<br />
<br />
Thank you for choosing Contest Prep Academy. Let's embark on this transformative fitness journey together!
<br />
<br />
Delta Infox
                        </p>
                    
                      
                      </div>
                 
                  
                    </div>
                  </div>
                </div>

             
              </div>
            </div>
         
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About2;
