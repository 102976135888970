


const title = "Subscription Features";
const btnText = "Enroll Now";

const skillList = [
 
    {
        imgUrl: require('../../assets/images/skill/icon/03.jpg'),
        imgAlt: 'skill rajibraj91 rajibraj',
        title: 'Vlogs',
        desc: 'You pick the schedule.',
    },

]


const Skill2 = () => {
    return (
        <div style={{paddingTop: '110px' , paddingBottom: '110px'}} className="skill-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-12">
                        <div className="section-header">
                            <h2 className="title">{title}</h2>
                            <button className="lab-btn"><span>{btnText}</span></button>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">
                        <div className="section-wrpper">
                            <div className="row g-4 justify-content-center row-cols-sm-2 row-cols-1">
                                {skillList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="skill-item">
                                            <div className="skill-inner">
                                                <div className="skill-thumb">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="skill-content">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Skill2;