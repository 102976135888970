import React, { Fragment, useEffect, useState } from "react";
import CourseTemp from "./CourseTemp";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import './Testing.scss'

const Testing = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
        //   setLoad(false)
      });
    });

    return () => unsub();
  }, []);


  

  return (
    <Fragment>
        <script src="./script.js"/>
      <CourseTemp>
<>
<header className="headerrrrr">
  <div class="triangle left"><h1>This is a title</h1></div>
  <div class="triangle right"><h1>This is a title</h1></div>
  <button></button>
</header>

<nav className="navvvvvvvvvvvvvvv">
  <ul>
    <li>Section 1</li>
    <li>Section 2</li>
    <li>Section 3</li>
  </ul>
  <div id="nav-close"><i class="fas fa-times"></i></div>
</nav>

<content>
  <section>Section 1</section>
  <section>Section 2</section>
  <section>Section 3</section>
</content>
</>

      </CourseTemp>
    </Fragment>
  );
};

export default Testing;
