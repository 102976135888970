import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import PageHeader from "../../component/layout/pageheader";
import GoogleMap from "../../component/sidebar/googlemap";
import Footer from "../../component/layout/footer";
import emailjs from "@emailjs/browser";
import { db } from "../../firebase/Firebase";
import { toast } from "react-hot-toast";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import React, { useState, useRef } from "react";

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle =
  "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send our Message";

const contactList = [

  {
    imgUrl: require("../../assets/images/icon/02.png"),
    imgAlt: "contact icon",
    title: "Phone number",
    desc: "+91 8264005558",
  },
  {
    imgUrl: require("../../assets/images/icon/03.png"),
    imgAlt: "contact icon",
    title: "Send email",
    desc: "info@deltainfox.com",
  },
  {
    imgUrl: require("../../assets/images/icon/04.png"),
    imgAlt: "contact icon",
    title: "Our website",
    desc: "www.deltainfox.com",
  },
];

const ContactPage = () => {
  const form = useRef();

  const [name, setName] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [message, setmessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();
    if (!name || !email || !phone) {
      toast.error("Please Fill The Form");
    } else {
      emailjs
        .sendForm(
          "service_8zxl673",
          "template_t2nlr9f",
          form.current,
          "mGFKpa6Q60RYTwD8D"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );

      addDoc(collection(db, "ContactUs"), {
        name,
        phone,
        email,
        message,
        createdAt: serverTimestamp(),
      }).then(() => {
        toast.success("Sended Successfully");
        setName("");
        setemail("");
        setphone("");
        setmessage("");
      });
    }
  };

  return (
    <Fragment>
      <HeaderTwo />
      <PageHeader title={"Get In Touch With Us"} curPage={"Contact Us"} />
      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="map-address-section padding-tb section-bg"
      >
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{subTitle}</span>
            <h2 className="title">{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row " style={{justifyContent:'center'}}>
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="contact-wrapper">
                  {contactList.map((val, i) => (
                    <div className="contact-item" key={i}>
                      <div className="contact-thumb">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="contact-content">
                        <h6 className="title">{val.title}</h6>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
         
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="contact-section padding-tb"
      >
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{conSubTitle}</span>
            <h2 className="title">{conTitle}</h2>
          </div>
          <div className="section-wrapper">
            <form
              className="contact-form"
              ref={form}
              onSubmit={sendEmail}
              autocomplete="on"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name *"
                />
              </div>
              <div className="form-group">
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="email"
                  name="email"
                  placeholder="Your Email *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                  name="contact-no"
                  placeholder="Mobile Number *"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder="Your Subject *"
                />
              </div>
              <div className="form-group w-100">
                <textarea
                  rows="8"
                  value={message}
                  onChange={(e) => setmessage(e.target.value)}
                  name="message"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="form-group w-100 text-center">
                <button onClick={sendEmail} className="lab-btn">
                  <span>{btnText}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
