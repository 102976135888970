/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/jsx-pascal-case */
import {
  getDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import { auth, db } from "../../firebase/Firebase";

import "./Profile.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import React, { useEffect, useState } from "react";
import Progress_bar from "../../component/sidebar/PercentLine";
import Image1 from "../../assets/images/logo/Artboard2.jpg";
import logo from "../../assets/images/logo/png.png";
import { toast } from "react-hot-toast";
import moment from 'moment'

const ProfileBody = () => {
  const navigate = useNavigate();

  const user = auth.currentUser;
  const uid = auth.currentUser.uid;

  const [getUser, setGetUser] = useState();
  const [displayName, setDisplayName] = useState();

  useEffect(() => {
    getDoc(doc(db, "usersLesson", uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
        var str = docSnap.data().name;
        var matches = str.match(/\b(\w)/g);
        setDisplayName(matches.join(""));
      } else {
        console.log("No Document Found");
      }
    });
  }, [user?.uid]);

  const [Total, setTotal] = useState();

  useEffect(() => {
    getDoc(doc(db, "ContestPrepAcademy", "Module1")).then((docSnap) => {
      if (docSnap.exists()) {
        setTotal(docSnap.data().TotalModule);
      }
    });
  }, [uid]);

  const [memebrship, setMembership] = useState();

  useEffect(() => {
    getDoc(
      doc(
        db,
        "usersLesson",
        user?.uid,
        "MemberShip",
        "ContestPrepCertification"
      )
    ).then((docSnap) => {
      if (docSnap.exists()) {
        setMembership(docSnap.data());
      }
    });
  }, [user?.uid]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };

      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "scroll";
  }

  const displayRazorpay = () => {
    try {
      const res = loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!res) {
        alert("You are offline... Failed to load Razorpay SDK");
      }

      const options = {
        key: "rzp_live_8u1YZKSi5THsSt",
        currency: "INR",
        amount: 15000 * 100,
        name: "Delta Infox LLP",
        description: "Thanks for purchasing",
        image: logo,

        theme: {
          color: "#bc0000",
        },

        handler: function (response) {
          closeModal();
          // onOpenModal();

          updateDoc(
            doc(
              db,
              "usersLesson",
              uid,
              "MemberShip",
              "ContestPrepCertification"
            ),
            {
              unlock: true,
            }
          ).then(() => {
            navigate(0)
            toast.success('Successfully Paid')
          })

          //   navigate('/OrderConfirmed')
        },
        prefill: {
          name: user?.name,
          email: user?.email,
          contact: user?.phone,
        },

        // if(response.razorpay_payment_id)
      };

      const options2 = {
        key: "rzp_live_8u1YZKSi5THsSt",
        currency: "INR",
        amount: 25000 * 100,
        name: "Delta Infox LLP",
        description: "Thanks for purchasing",
        image: logo,

        theme: {
          color: "#bc0000",
        },

        handler: function (response) {
          closeModal();
          // onOpenModal();

          updateDoc(
            doc(
              db,
              "usersLesson",
              uid,
              "MemberShip",
              "ContestPrepCertification"
            ),
            {
              unlock: true,
            }
          ).then(() => {
            navigate(0);
            toast.success('Successfully Paid')
          })

          //   navigate('/OrderConfirmed')
        },
        prefill: {
          name: user?.name,
          email: user?.email,
          contact: user?.phone,
        },

        // if(response.razorpay_payment_id)
      };
      const paymentObject = new window.Razorpay(
        memebrship.Subscription === "VIP" ? options2 : options
      );
      paymentObject.open();
    } catch (error) {
      alert(error);
    }
  };

  const [sub, setSub] = useState();
  useEffect(() => {
    getDoc(
      doc(db, "usersLesson", uid, "MemberShip", "ContestPrepSubscription")
    ).then((docSnap) => {
      if (docSnap.exists()) {
        //    console.log(docSnap?.data().razorpay_subscription_id)

        setSub({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    });
  }, [uid]);




  return (
    <div className="ProfileBody">
      <div class="container">
        <div class="profile-header">
          <div class="profile-img">
            <p width="200" alt="Profile Image">
              {displayName}
            </p>
          </div>
          <div class="profile-nav-info">
            <h3 class="user-name">{getUser?.name}</h3>
            <div class="address">
              <p id="state" class="state">
                {getUser?.email}
              </p>
            </div>
          </div>
          <div class="profile-option">
            <div class="notification">
              <i class="bx bxs-bell"></i>
              <span class="alert-message">0</span>
            </div>
          </div>
        </div>

        <div class="main-bd">
          <div class="left-side">
            <div class="profile-side">
              <div class="user-bio">
                <h3>Any Query</h3>
                <p class="bio">
                If you are facing any issue with the website or app direct to the below link!
                </p>
              </div>
              <div class="profile-btn">
                <a target="_blank" href="https://wa.me/917717402991" class="chatbtn" id="chatBtn">
                  Contact Us
                  <i class="bx bxl-whatsapp"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="right-side">
           {sub ? (
            <>
                 <table className="tb-1">
                      <thead>
                        <tr className="tr-plan">
                       
                          <th className="th-plan" scope="col">
                            Subscription
                          </th>
                          <th className="th-plan " scope="col">
                            Active
                          </th>
                          <th className="th-plan" scope="col">
                            Created
                          </th>
                          <th className="th-plan" scope="col">
                            Renew
                          </th>
                          <th className="th-plan" scope="col">
                            Payment Method.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="tr-plan">
                      
                          <td
                            className="td-plan"
                            data-label="     Subscription"
                          >
                            Contest Prep Subscription
                          </td>
                          <td className="td-plan yes-1" data-label="Active ">
                            {sub?.status === 'active' ? "Yes" : "No"}
                          </td>
                          <td className="td-plan" data-label=" Created">
                        {  moment.unix(sub?.current_start).format("MM/DD/YYYY")}
                          </td>
                          <td className="td-plan" data-label="Expires">
                          {  moment.unix(sub?.current_end).format("MM/DD/YYYY")}
                          </td>
                          <td className="td-plan" style={{textTransform: 'capitalize'}} data-label=" Payment Method">
                            {" "}
                            {sub?.payment_method}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
            </>
           ) : (
<>
<Tabs className="profilereacttabmain">
              <TabList className="profilereactabs">
                <Tab className="profilereactabsbutton">My Course</Tab>
                <Tab className="profilereactabsbutton">Membership</Tab>
              </TabList>

              <TabPanel>
                <div className="TabPanelReactTabs">
                  <div className="CourseMainCard">
                    <img className="CourseMainCardImg" src={Image1} alt="" />
                    <div className="CourseMainCardBody">
                      <h1>
                        Personal Trainer and Advanced Nutrition Certification
                      </h1>
                      <Progress_bar
                        bgcolor="#bc0000"
                        progress={(parseInt(getUser?.Module1) / Total) * 100}
                        height={5}
                      />
                      <div>
                        <h1 className="percentage-course-com">
                          {((parseInt(getUser?.Module1) / Total) * 100).toFixed(
                            2
                          )}
                          % Complete
                        </h1>
                      </div>
                      <button
                        onClick={() => navigate("/Modules")}
                        class="button-10"
                        role="button"
                      >
                        My Course
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="TabPanelReactTabs">
                  <div>
                    <div>
                      {memebrship?.unlock === false ? (
                        <>
                          <button
                            onClick={() => displayRazorpay()}
                            class="button-43"
                            role="button"
                          >
                            PAY SECOND EMI
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <table className="tb-1">
                      <thead>
                        <tr className="tr-plan">
                          <th className="th-plan" scope="col">
                            Membership
                          </th>
                          <th className="th-plan" scope="col">
                            Subscription
                          </th>
                          <th className="th-plan " scope="col">
                            Active
                          </th>
                          <th className="th-plan" scope="col">
                            Created
                          </th>
                          <th className="th-plan" scope="col">
                            Expires
                          </th>
                          <th className="th-plan" scope="col">
                            Payment Method.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="tr-plan">
                          <td className="td-plan" data-label="  Membership">
                            {memebrship?.MemberShip}
                          </td>
                          <td
                            className="td-plan"
                            data-label="     Subscription"
                          >
                            {memebrship?.Subscription}
                          </td>
                          <td className="td-plan yes-1" data-label="Active ">
                            {memebrship?.Active === true ? "Yes" : "No"}
                          </td>
                          <td className="td-plan" data-label=" Created">
                            {memebrship?.createdAt
                              ?.toDate()
                              .toLocaleDateString()}
                          </td>
                          <td className="td-plan" data-label="Expires">
                            {memebrship?.Expires}
                          </td>
                          <td className="td-plan" data-label=" Payment Method">
                            {" "}
                            {memebrship?.PaymentMethod}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
</>

           )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBody;
