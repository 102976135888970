/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState  , useContext} from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {toast} from 'react-hot-toast'

const HeaderTwoAuth = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setHeaderFiexd(true);
    } else {
      setHeaderFiexd(false);
    }
  });

  const { userLogout } = useContext(AuthContext);


  
  const handleLogout = () => {
    userLogout()
      .then((result) => {
        // toast.success("Successfully Logout!");
        toast.success('Successfully Logout!', {
            position: "top-center"
          })
    

      })
      .catch((error) => console.error(error));
  };


  return (
    <header
      className={`header-section style-2 ${
        headerFiexd ? "header-fixed fadeInUp" : ""
      }`}
    >
      <div className="header-bottom">
        <div className="header-wrapper">
          <div className="logo-search-acte">
            <div className="logo">
              <Link to="/Home">
                <img
                  src={require("../../assets/images/logo/01.png")}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="cate-search d-none d-xxl-block">
              <div className="d-flex flex-wrap align-items-center">
                <div className="header-cate">
                  {/* <SelectCatagory select={'all'} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="menu-area">
            <div className="menu">
              <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                <li>
                  <NavLink to="/Home">Home</NavLink>
                </li>
                {/* <li className="menu-item-has-children">
                                    <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">Courses</a>
                                    <ul className="lab-ul dropdown-menu">
                                  
                                        <li><NavLink to="/CourseDetailStandard">Standard Membership</NavLink></li>
                                        <li><NavLink to="/CourseDetailPremium">Premium Membership</NavLink></li>
                                    </ul>
                                </li> */}

                {/* <li>
                  <NavLink to="/CourseDetailStandard">Course</NavLink>
                </li> */}

             
{/* 
                <li>
                  <NavLink to="/About">About</NavLink>
                </li>

                <li>
                  <NavLink to="/ContactUs">Contact</NavLink>
                </li> */}
              </ul>
            </div>

            {/* <Link to="/Login" className="login">
              <i className="icofont-user"></i> <span>LOG IN</span>{" "}
            </Link> */}
            <button onClick={() => handleLogout()} className="signup">
              <i className="icofont-users"></i> <span>LOG OUT</span>{" "}
            </button>

            <div
              className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
              onClick={() => setMenuToggle(!menuToggle)}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderTwoAuth;
