/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from "react";
import "./Module.css";
import CourseTemp from "../Courses/CourseTemp";
import ModuleBody from "./ModuleBody";

const Modules = () => {
  return (
    <Fragment>
      <CourseTemp>
        <ModuleBody />
      </CourseTemp>
    </Fragment>
  );
};

export default Modules;
