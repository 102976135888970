import React, { useEffect, useMemo, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import "./Chapter.css";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import moment from "moment";
import Spinner2 from "./Spinner2";
import QuestionReply from "./QuestionReply";

const QuestionSlider = ({
  name,
  chapterData,
  item,
  sliderload,
  data,
  setData,
  exist,
  setExist,
}) => {
  const [loader, setLoader] = useState(false);

  const [chapter1, setChapter1] = useState(chapterData);
  const [item1, setItem1] = useState(item);

  useEffect(() => {
    setChapter1(chapterData);
    setItem1(item);
  }, [item, chapterData]);
  const obj = useMemo(() => ({ chapter1 }), [chapter1]);

  useEffect(() => {
    if (sliderload === true) {
      const todosref = collection(
        db,
        "ContestPrepAcademy",
        item?.id,
        item?.id,
        chapter1?.id,
        "Forums"
      );
      const q = query(todosref, where("verified", "==", "approved"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let Blog = [];
        if (querySnapshot.empty) {
          setLoader(false);
          setExist(true);
          setData([]);
        } else {
          querySnapshot.forEach((doc) => {
            Blog.push({
              id: doc.id,
              ...doc.data(),
            });
            setData(Blog);
            setLoader(false);
            setExist(false);
          });
        }
      });

      return () => unsub();
    }
  }, [obj]);

  const onClick = () => {
    if (sliderload === true) {
      const todosref = collection(
        db,
        "ContestPrepAcademy",
        item?.id,
        item?.id,
        chapter1?.id,
        "Forums"
      );
      const q = query(todosref, where("verified", "==", "approved"));

      const unsub = onSnapshot(q, (querySnapshot) => {
        let Blog = [];
        if (querySnapshot.empty) {
          setLoader(false);
          setExist(true);
          setData([]);
        } else {
          querySnapshot.forEach((doc) => {
            Blog.push({
              id: doc.id,
              ...doc.data(),
            });
            setData(Blog);
            setLoader(false);
            setExist(false);
          });
        }
      });

      return () => unsub();
    }
  };

  return (
    <>
      {loader === true ? (
        <>
          <Spinner2 />
        </>
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              <ScrollContainer className={name}>
                {data?.map((item, key) => (
                  <>
                    <div className="QuestionCard1">
                      <div className="QuestionCard1Top">
                        <div className="QuestionCardTopCircle"></div>
                        <div className="QuestionCardTopText textquestion">
                          <h1>{item?.user}</h1>
                        </div>
                        <div className="QuestionCardTopTime">
                          <h1>
                            {" "}
                            {moment(item?.createdAt).format("h:mm:ss a")}
                          </h1>
                        </div>
                      </div>
                      <div className="QuestionCard1Middle">
                        <h1>
                          {item?.name} <br />
                          {item?.description}
                        </h1>
                      </div>
                      <QuestionReply
                        item={item}
                        item1={item1}
                        chapter={chapter1}
                      />
                    </div>
                  </>
                ))}
              </ScrollContainer>
            </>
          ) : (
            <>
              {exist === true ? (
                <button>No Question Available</button>
              ) : (
                <button onClick={() => onClick()}>Click Here to Load</button>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default QuestionSlider;
