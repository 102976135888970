import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebase/Firebase";
import { doc, getDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const CaseStudycards = ({ item }) => {
  const navigate = useNavigate();

  const [sub, setSub] = useState();
  const uid = auth?.currentUser.uid;
  useEffect(() => {
    getDoc(
      doc(db, "usersLesson", uid, "MemberShip", "ContestPrepSubscription")
    ).then((docSnap) => {
      if (docSnap.exists()) {
        //    console.log(docSnap?.data().razorpay_subscription_id)

        setSub({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    });
  }, [uid]);


  return (
   <>
   {sub ? (
     <Fragment>
     <div className="case-card-d2"    onClick={() =>
                             toast.error('Case Study Locked')
                           }
                           
                           >
       <div className="image-case-section" >
         <img className="card-case-img" src={item.Poster} alt="" />
       </div>
       <div className="content-case-study">
         <h1 className="athlete-name-case">{item.id}</h1>
         <p className="athlete-p-tab">
          {item.description}.Read More
         </p> 
       </div>
     </div>
   </Fragment>
   ) : (
    <Fragment>
    <div className="case-card-d2"    onClick={() =>
                            navigate(`/CaseStudyDate/${item.id}`)
                          }
                          
                          >
      <div className="image-case-section" >
        <img className="card-case-img" src={item.Poster} alt="" />
      </div>
      <div className="content-case-study">
        <h1 className="athlete-name-case">{item.id}</h1>
        <p className="athlete-p-tab">
         {item.description}.Read More
        </p> 
      </div>
    </div>
  </Fragment>
   )}
   </>
  );
};

export default CaseStudycards;
