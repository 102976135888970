import React , {Fragment} from 'react'
import './Profile.css'
import CourseTemp from '../Courses/CourseTemp'
import ProfileBody from './ProfileBody'

const Profile = () => {
  return (
    <Fragment>
        <CourseTemp>
            <ProfileBody />
        </CourseTemp>
    </Fragment>
  )
}

export default Profile