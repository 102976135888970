import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";

const app = firebase.initializeApp({
  apiKey: "AIzaSyCrpXnaz-ZaU74dCLT_Lz75DLaR8-wWsvU",
  authDomain: "contestprepacademy-ef094.firebaseapp.com",
  projectId: "contestprepacademy-ef094",
  storageBucket: "contestprepacademy-ef094.appspot.com",
  messagingSenderId: "553480201280",
  appId: "1:553480201280:web:4aaa60aa028f2d04096ba5",
});
const auth = getAuth(app);
const db = getFirestore(app);
export const storage = getStorage(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password).then(() => {});
  } catch (err) {
    alert(err);
  }
};

const logout = () => {
  signOut(auth);
};
export { auth, db, logInWithEmailAndPassword, app, logout };

export const database = getAuth(app);
export const messaging = getMessaging(app);
