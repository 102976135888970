import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../../firebase/Firebase";

const subTitle = "Welcome To";
const title = (
  <h2 style={{color:'white'}} className="title">
    <span></span> Contest Prep Academy
  </h2>
);
const desc = "";
const btnText = "View Course";

const BannerFive = () => {
  const uid = auth.currentUser.uid;

  const [data, setData] = useState();

  const fetchData = async (item) => {
    const Subscription = await fetch(
      `https://us-central1-nodejs-f34dd.cloudfunctions.net/app/subscriptions/${item}`,
      {
        method: "POST",
      }
    ).then((t) => t.json());

    //   console.log(Subscription)

    setData(Subscription);
  };

  useEffect(() => {
    getDoc(
      doc(db, "usersLesson", uid, "MemberShip", "ContestPrepSubscription")
    ).then((docSnap) => {
      if (docSnap.exists()) {
        //    console.log(docSnap?.data().razorpay_subscription_id)

        fetchData(docSnap.data().razorpay_subscription_id);

        const washingtonRef = doc(
          db,
          "usersLesson",
          uid,
          "MemberShip",
          "ContestPrepSubscription"
        );

        // Set the "capital" field of the city 'DC'
        updateDoc(washingtonRef, {
            loginPermission: true,
            ...data,
        }).then(() => {
          console.log("Updated Successfully");
        });
      } else {
        console.log("No such document!");
      }
    });
  }, [data, uid]);

  return (
    <section className="banner-section style-5">
      <div className="container">
        <div className="section-wrapper">
          <div className="row">
            <div className="offset-md-6 col-xl-6">
              <div className="banner-content">
                <p  className="desc">{desc}</p>
                <h4 style={{color:'white'}}  className="subtitle">{subTitle}</h4>
                {title}
                {data?.status === "active" ? (

              
                    <Link to={"/Vlogs"} className="lab-btn">
                  <span  style={{color:'white'}}>{btnText}</span>
                </Link>
                ) : (
                    <Link to={"/Courses"} className="lab-btn">
                    <span style={{color:'white'}}>{btnText}</span>
                  </Link>
                )} 
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerFive;
