import React, { Fragment } from 'react'
import HeaderTwo from '../../component/layout/header-2'
import Footer from '../../component/layout/footer'
import './Pricing.css'
import Compare3 from '../../component/section/Compare3'
const Pricing = () => {
  return (
    <Fragment>
    <HeaderTwo />

    <div
      style={{ paddingTop: "110px", paddingBottom: "110px" }}
      className="course-single-section padding-tb section-bg"
    >
      <div>
      

<Compare3/>
        
      </div>
    </div>
    <Footer/>

  </Fragment>
  )
}

export default Pricing