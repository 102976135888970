import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import Respond from "../../component/sidebar/respond";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";

import PageHeaderTwo2 from "../../component/layout/pageheader22";
import CourseSideDetail2 from "../../component/sidebar/course-detail2";

const Privacy = () => {
  const [module, setModule] = useState();

  useEffect(() => {
    const todosref = collection(db, "ContestPrepAcademy");
    const q = query(todosref);
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setModule(Blog);
      });
    });

    return () => unsub();
  }, []);

  return (
    <Fragment>
      <HeaderTwo />

      <div
        style={{ paddingTop: "110px", paddingBottom: "110px" }}
        className="course-single-section padding-tb section-bg"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3 className=" text-center p-5">Privacy Policy</h3>
                      <div className="term-a3">
                        <div>
                          <p className="term-p">
                            Welcome to Contest Prep Academy, operated by Delta
                            Infox! We are committed to protecting your privacy
                            and ensuring the security of your personal
                            information. Please carefully review our Privacy
                            Policy to understand how we collect, use, and
                            safeguard your data.
                          </p>
                          <br />
                          <p className="term-p">
                            1. Information We Collect:
                            <br />
                            <br />
                            a. Personal Information: When you enroll in Contest
                            Prep Academy, we may collect personal information
                            such as your name, email address, and payment
                            details.
                            <br />
                            <br />
                            b. Usage Data: We gather information about how you
                            interact with our platform, including your course
                            progress, quiz scores, and time spent on the site.
                            <br />
                            <br />
                            c. Device Information: We may collect information
                            about the device you use to access Contest Prep
                            Academy, including IP address, browser type, and
                            operating system.
                          </p>
                          <br />
                          <p className="term-p">
                            2. How We Use Your Information:
                            <br />
                            <br />
                            a. Course Delivery: We use your personal information
                            to provide access to Contest Prep Academy, deliver
                            course materials, and track your progress.
                            <br />
                            <br />
                            b. Communication: We may use your email address to
                            send important course updates, announcements, and
                            promotional materials. You can opt-out of
                            promotional emails at any time.
                            <br />
                            <br />
                            c. Analytics: We analyze usage data to improve the
                            quality of our course content, identify trends, and
                            enhance the user experience.
                            <br />
                            <br />
                            d. Payment Processing: Your payment information is
                            used for processing enrollment fees securely. We do
                            not store your payment details on our servers.
                          </p>
                          <br />

                          <p className="term-p">
                            3. Data Security:
                            <br />
                            <br />
                            a. We implement industry-standard security measures
                            to protect your personal information from
                            unauthorized access, disclosure, alteration, and
                            destruction.
                            <br />
                            <br />
                            b. Your payment information is encrypted using
                            secure socket layer technology (SSL) to ensure the
                            confidentiality of financial transactions.
                          </p>
                          <br />

                          <p className="term-p">
                            4. Third-Party Services:
                            <br />
                            <br />
                            a. Contest Prep Academy may use third-party
                            services, such as payment processors and analytics
                            tools, to enhance the functionality of our platform.
                            <br />
                            <br />
                            b. These third parties may have their own privacy
                            policies. We recommend reviewing their policies to
                            understand how your data is handled.
                          </p>
                          <br />

                          <p className="term-p">
                            5. Cookies and Tracking Technologies:
                            <br />
                            <br />
                            a. We use cookies and similar technologies to
                            enhance your experience on Contest Prep Academy. You
                            can adjust your browser settings to manage or
                            disable cookies.
                          </p>
                          <br />

                          <p className="term-p">
                            6. Your Choices:
                            <br />
                            <br />
                            a. You have the right to review and update your
                            personal information. You can also request the
                            deletion of your account by contacting us at
                            info@deltainfox.com.
                            <br />
                            <br />
                            b. You can opt-out of receiving promotional emails
                            by using the unsubscribe link provided in each
                            email.
                          </p>
                          <br />
                          <p className="term-p">
                            7. Changes to Privacy Policy:
                            <br />
                            <br />
                            a. We may update this Privacy Policy to reflect
                            changes in our practices. The latest version will
                            always be available on Contest Prep Academy.
                          </p>
                          <br />
                          <p className="term-p">
                          8. Contact Us:

                            <br />
                            <br />
                            If you have any questions or concerns regarding this Privacy Policy or the handling of your data, please contact us at info@deltainfox.com.
<br />
<br />

Thank you for trusting Delta InfoX with your personal information. We are dedicated to providing you with a secure and enjoyable learning experience at Contest Prep Academy.
<br />
<br />

Delta Infox
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Privacy;
