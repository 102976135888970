import React from "react";
import { Fragment } from "react";
import HeaderTwo from "../../component/layout/header-2";
import BannerTwo from "../../component/section/banner-2";
import AboutTwo from "../../component/section/about-2";
import CourseTwo from "../../component/section/course-2";
import CategoryTwo from "../../component/section/category-2";
import Footer from "../../component/layout/footer";
import Compare2 from "../../component/section/Compare2";
import Subscriptioncard1 from "../../component/section/Subscriptioncard1";
import Compare3 from "../../component/section/Compare3";

const Home = () => {
  return (
    <Fragment>
      <HeaderTwo />
      <BannerTwo />
      <AboutTwo />
      <Subscriptioncard1 />
      <CourseTwo />
      <Compare3/>
      <CategoryTwo />
      {/* <Student /> */}

      <Footer />
    </Fragment>
  );
};

export default Home;
