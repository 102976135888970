import React, { Fragment, useState } from "react";
import "./VlogStyle.css";
import { useLocation } from "react-router-dom";
import Accordition4 from "./Accordtion4";
import ReactPlayer from "react-player";

const VlogPlayerBody = () => {
  const location = useLocation();

  const item = location?.state?.item;
  const vlogs = location?.state?.vlogs;

  const [data, setData] = useState(item);

  const onChangeLesson = (item, key) => {
    setData(item)
  };

  return (
    <div className="VlogPlayerBody">
      <Fragment>
        <div className="course-view-section padding-tb section-bg section-bgggg">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="course-view">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-12">
                      <div className="video-part mb-4 mb-lg-0">
                        <div className="vp-title mb-4 mt-4">
                          <h3 className="headingchaptername">{data?.name}</h3>
                        </div>
                        <div className="vp-video mb-4">
                          <ReactPlayer
                            className="reactplayerVlog"
                      
                            light={data?.thumbnail?data?.thumbnail   : require ('../../../assets/images/bg-module.jpg')  }
                            width={"100%"}
                            height={"500px"}
                            url={data?.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                            controls
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                },
                              },
                            }}
                          />
                        
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-8 col-12">
                      <div className="video-list-area">
                        <div className="video-head"></div>
                        <div className="video-body">
                          <div className="course-select-area border-radius-12">
                            <div className="csa-body heifhtsetcsabody">
                              <ul className="lab-ul">
                                <li>
                                  <div id="accordion">
                                    <>
                                      <div className="card active bg-ash mb-1">
                                        <div
                                          className="card-header bg-transparent border-bottom-0"
                                          id={`acc-list-1`}
                                        >
                                          <h5 className="mb-0">
                                            <button
                                              className="w-100 border-0 bg-transparent outline-none text-left"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#acc-1`}
                                              aria-expanded="true"
                                              aria-controls={`#acc1`}
                                            >
                                              {data.name}:
                                              <span className="d-block font-weight-normal">
                                                Videos: {vlogs?.length}
                                              </span>
                                              <div className="icon">
                                                <i className="icofont-thin-down"></i>
                                              </div>
                                            </button>
                                          </h5>
                                        </div>
                                        <div
                                          id={`acc-1`}
                                          className="collapse show"
                                          aria-labelledby={`acc-list-1`}
                                          data-bs-parent="#accordion"
                                        >
                                          <div className="card-body py-0">
                                            <Accordition4
                                              onChangeLesson={onChangeLesson}
                                              vlogs={vlogs}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default VlogPlayerBody;
