/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Forum.css";
import "./Forum2.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useEffect } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase/Firebase";
import { useMemo } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { FaUserCircle } from "react-icons/fa";
import { BsFillReplyFill } from "react-icons/bs";
import { BiSolidMessageAdd } from "react-icons/bi";
import { AiOutlineSend } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import { FileUploader } from "react-drag-drop-files";
import Modal from "react-modal";
import Loader from "../../component/sidebar/Loader";
import { v4 as uuidv4 } from "uuid";
import uploadFileProgress from "../../Helpers/uploadFileProgress";
import { Box, ImageList, ImageListItem } from "@mui/material";
import CircularProgressWithLabel from "../../Helpers/CircularProgressWithLabel";
import { CheckCircleOutline } from "@mui/icons-material";

const ForumBody = () => {
  const Moment = require("moment");
  const uid = auth.currentUser.uid;

  const fileTypes = ["JPEG", "PNG"];
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };

  const [uuid, setuuid] = useState(uuidv4());
  const [uuid2, setuuid2] = useState(uuidv4());

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
    setFile(null);
    setImageURL(null);
    setProgress(0);
    setName("");
    setDescription("");
    setTags("");
  }

  const [progress, setProgress] = useState(0);
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (file) {
      const uploadImage = async () => {
        const imageName = uuidv4() + "." + file?.name.split(".").pop();
        try {
          const url = await uploadFileProgress(
            file,
            `Forums/${uid}`,
            imageName,
            setProgress
          );
          // const galleryDoc = {
          //   imageURL: url,
          //   uid: currentUser?.uid || '',
          //   uEmail: currentUser?.email || '',
          //   uName: currentUser?.displayName || '',
          //   uPhoto: currentUser?.photoURL || '',
          // };
          // await addDocument('gallery', galleryDoc, imageName);
          setImageURL(url);
        } catch (error) {
          // setAlert({
          //   isAlert: true,
          //   severity: 'error',
          //   message: error.message,
          //   timeout: 8000,
          //   location: 'main',
          // });
          console.log(error);
        }
      };
      uploadImage();
    }
  }, [file]);

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [forum, setForum] = useState({});

  const { user } = useContext(AuthContext);

  const [getUser, setGetUser] = useState("");

  useEffect(() => {
    getDoc(doc(db, "usersLesson", user?.uid)).then((docSnap) => {
      if (docSnap.exists()) {
        setGetUser(docSnap.data());
      } else {
        console.log("No Document Found");
      }
    });
  }, [user?.uid]);

  useEffect(() => {
    const q = query(
      collection(db, "Forum"),
      where("verified", "==", "approved")
    );
    const unsub = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setForum(
        cities.sort((a, b) => new Moment(b.createdAt) - new Moment(a.createdAt))
        // cities.sort((a, b) =>
        // a.createdAt.seconds- b.createdAt.seconds
        //   // a.createdAt.seconds < b.createdAt.seconds ? -1 : 1
        // )
      );
      setLoad(false);
    });

    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search, setSearch] = useState("");

  const countries = useMemo(() => {
    if (!search) return forum;

    return forum.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, forum]);

  const addDiscussion1 = async () => {
    if (file) {
      try {
        await setDoc(doc(db, "Forum", uuid), {
          Image: imageURL,
          name: name,
          description,
          tags,
          user: getUser?.name,
          email: getUser?.email,
          verified: "pending",
          createdAt: moment().format(),
          replies: 0,
          lastreply: "0",
          userId: user.uid,
          uuid: uuid,
          path: `/ReplyData/${uuid}`,
        })
          .then(() => {
            addDoc(collection(db, "QuestionBankNotify"), {
              forum: `${getUser?.name} is posted a Forum`,
              path: `/ReplyData/${uuid}`,
              createdAt: serverTimestamp(),
              type: "type1",
            });
            setFile(null);
            setImageURL(null);
            setProgress(0);
            setuuid("");
            setuuid2("");
            toast("Forum will posted after approval", {
              duration: 4000,
              position: "top-center",
              // icon: "✔",
              style: {
                borderRadius: "10px",
                background: "#3499EE",
                color: "#fff",
              },
            });

            toast("Forum Added", {
              duration: 2000,
              position: "top-center",
              icon: "✔",
              style: {
                borderRadius: "10px",
                background: "#3499EE",
                color: "#fff",
              },
            });
            closeModal();
          })
          .then(() => {
            setName("");
            setDescription("");
            setFile();
            setTags("");
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      await setDoc(doc(db, "Forum", uuid), {
        name: name,
        description,
        tags,
        user: getUser?.name,
        email: getUser?.email,
        verified: "pending",
        createdAt: moment().format(),
        replies: 0,
        lastreply: "0",
        userId: user.uid,
        uuid: uuid,
        path: `/ReplyData/${uuid}`,
      })
        .then(() => {
          addDoc(collection(db, "QuestionBankNotify"), {
            forum: `${getUser?.name} is posted a Forum`,
            path: `/ReplyData/${uuid}`,
            createdAt: serverTimestamp(),
            type: "type1",
          });
          setImageURL(null);
          setFile(null);
          setProgress(0);
          setuuid("");
          setuuid2("");
          toast("Forum will posted after approval", {
            duration: 2000,
            position: "top-center",
            // icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          toast("Forum Added", {
            duration: 2000,
            position: "top-center",
            icon: "✔",
            style: {
              borderRadius: "10px",
              background: "#3499EE",
              color: "#fff",
            },
          });
          closeModal();
        })
        .then(() => {
          setName("");
          setDescription("");
          setFile();
          setTags("");
        });
    }
  };
  const [load, setLoad] = useState(true);

  if (load === true) {
    return <Loader />;
  } else {
    return (
      <div className="ForumBody">
        <div className="ForumBodySearch">
          <center>
            <div
              id="search-box"
              itemprop="mainEntity"
              itemscope="itemscope"
              itemtype="https://schema.org/WebSite"
            >
              <form id="search-fs">
                <i aria-hidden="true" class="fa fa-search"></i>
                <input
                  id="search-text"
                  itemprop="query-input"
                  name="q"
                  placeholder="Search here"
                  required=""
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <input name="max-results" type="hidden" value="9" />
                <button disabled>Search</button>
              </form>
            </div>
          </center>
        </div>
        <div className="ForumBodyMain">
          <div className="border-jh">
            <div className="main-comments-div">
              <div>
                {Array.isArray(forum)
                  ? countries.map((item, key) => {
                      return (
                        <div>
                          <div
                            onClick={() => navigate(`/ReplyData/${item.id}`)}
                            className="comment-1"
                          >
                            <div className="comm-gf">
                              <FaUserCircle className="svg-user" />

                              <div className="updates-time-rep">
                                <h1 className="h-h1">{item.name}</h1>
                                <div className="span-updates">
                                  <span className="hov-span1">
                                    <span>
                                      <BsFillReplyFill className="svg-fill" />
                                    </span>
                                    <span>{item.user}</span>
                                  </span>
                                  <span></span>
                                  <span className="hov-span1">
                                    {moment(item.createdAt).fromNow()}
                                  </span>

                                  <span>· {item.replies} Replies</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div class="float-container">
          <a onClick={openModal}>
            <BiSolidMessageAdd color="white" size={30} />
            <p>Ask Your Question?</p>
          </a>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <div className="header-modal-s">
            <div className="search-input">
              <input
                placeholder="Discussion Title"
                type="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="name"
                required
              />
            </div>
          </div>

          <hr />
          {/* textarea-modal */}
          <div>
            <div className="modal-text-area-data-1">
              <div className="text-area-modal-s">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="text-modal-area1"
                  placeholder="Type your discussion content here"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  required
                ></textarea>
              </div>
              <div className="upload-options">
                {/* <input type="file" name="" id="" /> */}
                <FileUploader
                  classes="drop_area"
                  multiple={false}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
              </div>
              {progress > 0 ? (
                <>
                  <ImageList
                    style={{ height: "100px" }}
                    rowHeight={100}
                    cols={6}
                  >
                    <ImageListItem cols={1} rows={1}>
                      <img
                        src={imageURL}
                        alt="gallery"
                        style={{ height: "100px" }}
                        loading="lazy"
                      />
                      <Box sx={backDrop}>
                        {progress < 100 ? (
                          <CircularProgressWithLabel value={progress} />
                        ) : (
                          <CheckCircleOutline
                            sx={{
                              width: 60,
                              height: 60,
                              color: "lightgreen",
                            }}
                          />
                        )}
                      </Box>
                    </ImageListItem>
                  </ImageList>
                </>
              ) : (
                <></>
              )}
            </div>
            <hr />
            <div className="section-1-text">
              <textarea
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="Type one or more tag, comma separated "
                className="text-area-modal-2"
                name=""
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
            <hr />
            <div className="check-box-post-btn">
              <div className="check-box-post-btn1">
                <button
                  disabled={!name || !description}
                  onClick={() => addDiscussion1()}
                  className="post-btn-dis"
                >
                  Send <AiOutlineSend />
                </button>
                <button onClick={closeModal} className="post-btn-dis">
                  Cancel <MdCancel />
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
};

export default ForumBody;

const backDrop = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(0,0,0, .5)",
};
