/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import "./Chapter.css";
import { useEffect } from "react";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../firebase/Firebase";
import { useState } from "react";
import moment from "moment";
import { RiMessage2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const QuestionReply = ({ item, item1, chapter }) => {
  const [item2, setItem2] = useState(item);

  const [data, setData] = useState();

  useEffect(() => {
    const todosref = collection(
      db,
      "ContestPrepAcademy",
      item1?.id,
      item1.id,
      chapter?.id,
      "Forums",
      item?.id,
      "reply"
    );
    const q = query(todosref, orderBy("createdAt", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      let Blog = [];
      querySnapshot.forEach((doc) => {
        Blog.push({
          id: doc.id,
          ...doc.data(),
        });
        setData(Blog);
      });
    });

    return () => unsub();
  }, [chapter?.id, item?.id, item1.id]);

  const navigate = useNavigate();

  return (
    <>
      {data?.length > 0 ? (
        <>
          {data?.slice(0).map((item, key) => (
            <>
              <div   onClick={() =>
                navigate("/QuestionReply", {
                  state: {
                    chapter: chapter.id,
                    item: item.id,
                    item1: item1.id,
                    item2: item2.id,
                  },
                })
              }
              style={{cursor: 'pointer'}} className="QuestionCard1Middle2">
                <div className="QuestionCard1Middle2Body">
                  <div className="QUestionCard1MiddleBodyTop">
                    <div className="QuestionCardTopText textquestion">
                      <h1 className="whitecolorh1text">{item?.user}</h1>
                    </div>
                    <div className="QuestionCardTopTime">
                      <h1 className="whitecolorh1texttime whitecolorseth1size">
                        {moment(item?.createdAt).fromNow()}
                      </h1>
                    </div>
                  </div>
                  <div className="QUestionCard1MiddleBodyMiddle">
                    <p>{item?.reply}</p>
                  </div>
                </div>
              </div>
              <div
                onClick={() =>
                  navigate("/QuestionReply", {
                    state: {
                      chapter: chapter.id,
                      item: item.id,
                      item1: item1.id,
                      item2: item2.id,
                    },
                  })
                }
                className="QuestionCard1Middle2Body2"
              >
                <RiMessage2Line size={17} color="black" />
                <span>{data?.length}</span>
              </div>
            </>
          ))}
        </>
      ) : (
        <>
          <>
            <div
              onClick={() =>
                navigate("/QuestionReply", {
                  state: {
                    chapter: chapter?.id,
                    item: item.id,
                    item1: item1.id,
                    item2: item2.id,
                  },
                })
              }
              style={{cursor: 'pointer'}}
            className="QuestionCard1Middle2">
              <div className="QuestionCard1Middle2Body">
                <div className="QUestionCard1MiddleBodyTop">
                  <div className="QuestionCardTopText textquestion">
                    <h1 className="whitecolorh1text">{/* {item?.user} */}</h1>
                  </div>
                  <div className="QuestionCardTopTime">
                    <h1 className="whitecolorh1texttime whitecolorseth1size"></h1>
                  </div>
                </div>
                <div className="QUestionCard1MiddleBodyMiddle">
                  <p>No Reply Yet!</p>
                </div>
              </div>
            </div>
            <div
              onClick={() =>
                navigate("/QuestionReply", {
                  state: {
                    chapter: chapter.id,
                    item: item.id,
                    item1: item1.id,
                    item2: item2.id,
                  },
                })
              }
              className="QuestionCard1Middle2Body2"
            >
              <RiMessage2Line size={17} color="black" />
              <span>0</span>
            </div>
          </>
        </>
      )}
    </>
  );
};

export default QuestionReply;
