
import React from 'react'
import CourseTemp from "../Courses/CourseTemp";
import LiveStreamBody from './LiveStreamBody';
import './LiveStream.css'

const LiveStream = () => {
  return (
    <CourseTemp>
        <LiveStreamBody />
    </CourseTemp>
  )
}

export default LiveStream