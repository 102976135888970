import React, { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const Compare3 = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [tab1, setTab1] = useState("tab1");

  const tabselect = (value) => {
    setTab1(value);
  };

  return (
    <>

<div className=' pricing-head'>
            <h1>
                Pricing Plan
            </h1>
            <p>
                Contest Prep Academy
            </p>
        </div>
      <Tabs>
        <div className="container mt-5 mb-5 pb-5">
          <section class="text-center">
            <div className="div-btn-sb-1">
              <div className="div-btn-sb2">
                <button
                  className={tab1 === "tab1" ? "sub-b1" : "sub-b2"}
                  onClick={() => tabselect("tab1")}
                >
                  OTP
                </button>
                <button
                  className={tab1 === "tab2" ? "sub-b1" : "sub-b2"}
                  onClick={() => tabselect("tab2")}
                >
                  EMI
                </button>
              </div>
            </div>

            {/* <div className="div-btn-sub-1">
              <button
                className={tab1 === "tab1" ? "sub-b1" : "sub-btn2"}
                onClick={() => tabselect("tab1")}
              >
                OTP
              </button>
              <button
                className={tab1 === "tab1" ? "sub-b1" : "sub-btn2"}
                onClick={() => tabselect("tab2")}
              >
                EMI
              </button>
            </div> */}
          </section>
          {tab1 === "tab1" ? (
            <div>
              <TabPanel>
                <div class="row" style={{ justifyContent: "center" }}>
                  <div class="col-md-3">
                    <div class="card border border-dark">
                      <div class="mx-2 card-body">
                        <h5 class="card-title my-2 ">Regular Membership</h5>
                      
                        <p class="h2 fw-bold">
                          ₹30,000
                          <small
                            class="text-muted"
                            style={{ fontSize: "18px" }}
                          ></small>
                        </p>
                        <Link
                          to={"/StandardMembership"}
                          style={{ color: "white" }}
                          class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                        >
                          Enroll Now
                        </Link>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          What's included
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>All Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card border border-dark">
                      <div class="mx-2 card-body">
                        <h5 class="card-title my-2 ">VIP Membership</h5>
                     
                        <p class="h2 fw-bold">
                          ₹50,000
                          <small
                            class="text-muted"
                            style={{ fontSize: "18px" }}
                          ></small>
                        </p>
                        <Link
                          to={"/PremiumMembership"}
                          style={{ color: "white" }}
                          class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                        >
                          Enroll Now
                        </Link>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          What's included
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>All Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                            <AiOutlineCheck size={18} color="green" />

                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                            <AiOutlineCheck size={18} color="green" />

                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                            <AiOutlineCheck size={18} color="green" />

                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                            <AiOutlineCheck size={18} color="green" />

                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card border border-dark">
                      <div class="mx-2 card-body">
                        <h5 class="card-title my-2 ">Monthly Subscription</h5>
                       
                        <p class="h2 fw-bold">
                          ₹999
                          <small
                            class="text-muted"
                            style={{ fontSize: "18px" }}
                          ></small>
                        </p>
                        <Link
                          to={"/MonthlySubscription"}
                          style={{ color: "white" }}
                          class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                        >
                          Enroll Now
                        </Link>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          What's included
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>All Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                            <RxCross1 size={18} color="red" />


                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          ) : (
            <div>
              <TabPanel>
                <div class="row">
                  <div class="col-md-3"></div>
                  <div class="col-md-3">
                    <div class="card border border-dark">
                      <div class="mx-2 card-body">
                        <h5 class="card-title my-2 ">Regular Membership</h5>
                        <p class="text-muted">
                          All the essentials for starting a business
                        </p>
                        <p class="h2 fw-bold">
                          ₹15,000
                          <small
                            class="text-muted"
                            style={{ fontSize: "18px" }}
                          >
                            /2 EMI's
                          </small>
                        </p>
                        <Link
                          to={"/StandardMembership"}
                          style={{ color: "white" }}
                          class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                        >
                          Enroll Now
                        </Link>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          1st EMI
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Half Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          2nd EMI
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>All Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="card border border-dark">
                      <div class="mx-2 card-body">
                        <h5 class="card-title my-2 ">VIP Membership</h5>
                        <p class="text-muted">
                          All the essentials for starting a business
                        </p>
                        <p class="h2 fw-bold">
                          ₹25,000
                          <small
                            class="text-muted"
                            style={{ fontSize: "18px" }}
                          >
                            /2 EMI's
                          </small>
                        </p>
                        <Link
                          to={"/PremiumMembership"}
                          style={{ color: "white" }}
                          class="btn btn-dark d-block mb-2 mt-3 text-capitalize"
                        >
                          Enroll Now
                        </Link>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          1st EMI
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Half Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <RxCross1 size={18} color="red" />
                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                      <div class="card-footer">
                        <p
                          class="text-uppercase fw-bold"
                          style={{ fontSize: "12px" }}
                        >
                          2nd EMI
                        </p>
                        <ol class="list-unstyled mb-0 px-4">
                          <p class="my-3 fw-bold text-muted text-center"></p>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Video Lectures</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>All Modules Unlocked</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Forums Section</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Case Study</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Vlogs</small>
                          </li>
                          <li class="mb-3">
                            <i class="fas fa-check text-success me-3">
                              <AiOutlineCheck size={18} color="green" />
                            </i>
                            <small>Live Streams</small>
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>
              </TabPanel>
            </div>
          )}
        </div>
      </Tabs>
    </>
  );
};

export default Compare3;
