import React, { Fragment, useEffect, useState } from "react";
import "./CaseStudy.css";
import CourseTemp from "../Courses/CourseTemp";
import { useNavigate, useParams } from "react-router-dom";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../firebase/Firebase";

const CaseStudyDate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [getdata, setGetData] = useState();

  useEffect(() => {
    const q = query(collection(db, `CaseStudy/${id}/${id}`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const cities = [];
      querySnapshot.forEach((doc) => {
        cities.push({ id: doc.id, ...doc.data() });
      });
      setGetData(cities);
      console.log(cities);
    });

    return () => {
      unsubscribe();
    };
  }, [id]);

  return (
    <Fragment>
      <CourseTemp>
        <h1 className="heading-case">Athlete Case Studies Dates</h1>

        <div className="dates-Athlete ">
          {getdata?.map((data) => (
            <div
              key={data.id}
              onClick={() =>
                navigate(`/CaseStudyDetails/${data.id}`, {
                  state: { detail: id },
                })
              }
              className="head-date1"
            >
              <div className="head-date3">
                <h1>{data.id}</h1>
              </div>
            </div>
          ))}
        </div>
      </CourseTemp>
    </Fragment>
  );
};

export default CaseStudyDate;
