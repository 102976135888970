import React from 'react'
import './Loader.css'
import { ClipLoader } from "react-spinners";

const Loader2 = () => {
  return (
    <div className="loader-sp">
    <ClipLoader size={60}   color={"black"}  />
  </div>
  )
}

export default Loader2