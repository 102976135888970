


const subTitle = "Online education";
const title = <h2 className="title"><span className="d-lg-block">LEVEL UP </span>YOUR BODYBUILDING EDUCATION <span className="d-lg-block"></span></h2>;
const desc = "Free online courses from the world’s Leading experts. join 18+ million Learners today.";


const catagoryList = [
    {
        name: 'Figma',
        link: '#',
    }, 
    {
        name: 'Adobe XD',
        link: '#',
    },
    {
        name: 'illustration',
        link: '#',
    },
    {
        name: 'Photoshop',
        link: '#',
    },
]


const BannerTwo = () => {
    return (
        <section >
<div className='home-post-1'>

<img src={require('../../assets/images/homeposter/home.webp')} alt="img" />
</div>





        </section>
    );
}
 
export default BannerTwo;